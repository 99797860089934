import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IndividualDiscountCard } from "./DiscountCards/IndividualDiscountCard";
import { SchoolDiscountCard } from "./DiscountCards/SchoolDiscountCard";
import { FitnessKidDiscountCard } from "./DiscountCards/FitnessKidDiscountCard";
import { TeamDiscountCard } from "./DiscountCards/TeamDiscountCard";
import { jwtDecode } from "jwt-decode";
import axios from 'axios';

import Plus from '../../assets/images/Plus.png';
import Cross from '../../assets/images/crossIcon.png';

const discountData = {
    Individual: {
        type: "individual",
        premium: {
            months: [
                { time: 1, cost: 60 },
                { time: 3, cost: 140 },
                { time: 6, cost: 240 },
                { time: 12, cost: 360 },
            ],
            entries: 'unlimited',
            discount: 0,
        },
        basic: {
            months: [
                { time: 1, cost: 35 },
                { time: 3, cost: 85 },
            ],
            entries: '8',
            discount: 0,
        },
    },
    School: {
        type: "School",
        Child: { months: 2, cost: 130, entries: 16 },
        PlusFiveYears: { months: 2, cost: 150, entries: 24 },
    },
    Team: {
        type: "Team",
        Children: {
            oneMonth: [
                { entries: "2 Hyrje në Javë - 8 Hyrje në Muaj", cost: 45, discount: 0 },
                { entries: "3 Hyrje në Javë - 12 Hyrje në Muaj", cost: 55, discount: 0 },
                { entries: "4 Hyrje në Javë - 16 Hyrje në Muaj", cost: 70, discount: 0 },
                { entries: "5 Hyrje në Javë - 20 Hyrje në Muaj", cost: 90, discount: 0 },
            ],
            threeMonths: [
                { entries: "2 Hyrje në Javë - 8 Hyrje në Muaj", cost: 115, discount: 0 },
                { entries: "3 Hyrje në Javë - 12 Hyrje në Muaj", cost: 135, discount: 0 },
                { entries: "4 Hyrje në Javë - 16 Hyrje në Muaj", cost: 155, discount: 0 },
                { entries: "5 Hyrje në Javë - 20 Hyrje në Muaj", cost: 200, discount: 0 },
            ],
        },
        Infants: {
            oneMonth: [{ entries: "2 Hyrje në Javë - 8 Hyrje në Muaj", cost: 50, discount: 0 }],
            threeMonths: [{ entries: "2 Hyrje në Javë - 8 Hyrje në Muaj", cost: 120, discount: 0 }],
        },
        Adults: {
            oneMonth: [{ entries: "2 Hyrje në Javë - 8 Hyrje në Muaj", cost: 50, discount: 0 }],
            threeMonths: [{ entries: "2 Hyrje në Javë - 8 Hyrje në Muaj", cost: 120, discount: 0 }],
        },
    },
    GroupReservations: {
        type: "GroupReservations",
        options: [
            { label: "Shfrytezimi i pishinës (2.5€/person)", cost: 2.5 },
            { label: "Pishinë + trajner (4€/person)", cost: 4 },
            { label: "Pishinë + trajner + transport (5.5€/person)", cost: 5.5 }
        ]
    }
};

export const InformationFormAgents = ({ step, setStep, formData, setFormData, setRegistrations, registrations, form, setForm }) => {
    const { t } = useTranslation();
    const [additionalDates, setAdditionalDates] = useState([""]);
    const [dropDown, setDropDown] = useState(false);
    const location = useLocation();
    const [checkbox, setCheckbox] = useState(false);

    const fetchAgentDetails = async () => {
        const queryParams = new URLSearchParams(window.location.search); // Get the token from URL params
        const id = queryParams.get('id'); // Extract 'id' from URL parameters
    
        if (id) {
            try {
                // Use axios to get the agent details by id
                let response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/agents/getagent/${id}`);
                const agent = response.data; // Extract the data from the response
    
                // Set the form data with the retrieved agent details
                setFormData({
                    agentName: agent.emri,
                });
            } catch (error) {
                console.error('Error fetching agent details:', error);
                toast.error('Error loading agent details.');
            }
        } else {
            console.error('No ID found in URL parameters');
        }
    };
    
    // Fetch the data when the component mounts and if location.search changes
    useEffect(() => {
        fetchAgentDetails();
    }, [location.search]); // Dependency on location.search ensures it runs when the URL parameters change
    
    
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const generateBatchId = () => {
        const timestamp = Date.now().toString(36);
        const randomNum = Math.floor(Math.random() * 1e12).toString(36);
        return `${timestamp}-${randomNum}`;
    };

    const handleRegister = async () => {
        const batchId = generateBatchId();

        if (registrations.length === 0) {
            await setFormData(prev => ({ ...prev, batchId }));
            setRegistrations(prevRegistrations => [...prevRegistrations, formData]);
        } else {
            const updatedRegistrations = registrations.map(registration => ({ ...registration, batchId }));
            setRegistrations(updatedRegistrations);
        }
    };

    const calculateTotalAmount = () => {
        const selectedOption = discountData.GroupReservations.options.find(option => option.label === formData.offer);
        return selectedOption ? selectedOption.cost * formData.numberOfPeople : 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setRegistrations(prevRegistrations => [...prevRegistrations, formData]);
        setFormData({
            firstName: '',
            lastName: '',
            dateOfBirth: '',
            address: '',
            phone: '',
            email: '',
            time: 0,
            timings: '',
            days: [],
            totalAmount: 0,
            payableAmount: 0,
            discount: 0,
            kidEntries: 0,
            swimmingExpertise: '',
            healthProblems: null,
            healthProblemDescription: '',
            type: formData?.type,
            premium: false,
            batchId: '0',
            paymentMethod: '',
            entries: '',
            ageGroup: '',
            agentName: '',
            age: 0,
            gender: ""
        });
    };

    const handleGroupReservationSubmit = async (e) => {
        e.preventDefault();
        console.log("Handling the regidtration of group",formData);

        if (!formData.institution || !formData.address || !formData.email || !formData.phone || !formData.responsiblePerson || !formData.dates || !formData.time || !formData.numberOfPeople || !formData.age || !formData.male || !formData.female || !formData.offer) {
            toast.error(t('please_fill_all_fields'));
            return;
        }

        if (!checkbox) {
            toast.error(t('agree_terms_conditions'));
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/groupreservation`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...formData,
                    totalAmount: calculateTotalAmount(),
                    dateCreated: new Date()
                })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            setRegistrations(prevRegistrations => [
                ...prevRegistrations,
                result,
            ]);

            setFormData({
                institution: '',
                address: '',
                email: '',
                phone: '',
                responsiblePerson: '',
                dates: [''],
                time: '',
                numberOfPeople: '',
                age: '',
                male: 0,
                female: 0,
                offer: '',
                agentName: '',
                totalAmount: 0,
                batchId: '0',
                dateCreated: Date.now()
            });

            toast.success('Rezervimi u shtua me sukses!');
        } catch (error) {
            console.error('Error:', error);
            toast.error('Pati një problem me dërgimin.');
        }
    };

    const handleRemoveRegistration = (index) => {
        const updatedRegistrations = registrations.filter((_, i) => i !== index);
        setRegistrations(updatedRegistrations);
    };

    const isDuplicateRegistration = () => {
        return registrations.some(registration => (
            registration.firstName === formData.firstName &&
            registration.lastName === formData.lastName &&
            registration.dateOfBirth === formData.dateOfBirth &&
            registration.address === formData.address &&
            registration.phone === formData.phone &&
            registration.email === formData.email &&
            registration.time === formData.time &&
            registration.totalAmount === formData.totalAmount &&
            registration.payableAmount === formData.payableAmount &&
            registration.discount === formData.discount &&
            registration.kidEntries === formData.kidEntries &&
            registration.swimmingExpertise === formData.swimmingExpertise &&
            registration.healthProblems === formData.healthProblems &&
            registration.type === formData.type &&
            registration.agentName === formData.agentName &&
            registration.premium === formData.premium
        ));
    };

    const isDuplicateGroupReservation = () => {
        return registrations.some(registration => (
            registration.institution === formData.institution &&
            registration.address === formData.address &&
            registration.email === formData.email &&
            registration.phone === formData.phone &&
            registration.responsiblePerson === formData.responsiblePerson &&
            registration.startDate === formData.startDate &&
            registration.endDate === formData.endDate &&
            registration.time === formData.time &&
            registration.numberOfPeople === formData.numberOfPeople &&
            registration.age === formData.age &&
            registration.gender === formData.gender &&
            registration.agentName === formData.agentName &&
            registration.offer === formData.offer
        ));
    };

    const addAdditionalDate = () => {
        setAdditionalDates([...additionalDates, ""]);
    };

    const handleAdditionalDateChange = (index, value) => {
        const newDates = [...additionalDates];
        newDates[index] = value;
        setAdditionalDates(newDates);
        setFormData((prevData) => ({
            ...prevData,
            dates: newDates,
        }));
    };


    const formMapping = {
        Individual: 'individual',
        "Kids Fitness": 'kids_fitness',
        "Shkolla e Notit": 'swimming_school',
        "Rezervime Grupore": 'group_reservations'
    };

    return (
        <div className="w-[95vw] md:w-[90vw] lg:w-[90vw] xl:w-[85vw] 2xl:w-[80vw] min-h-fit bg-white lg:container lg:mx-auto rounded-3xl px-5 md:px-10 lg:px-16 xl:px-20 pt-10 xl:pt-10 pb-10 flex flex-col font-custom2 mb-10">
           <div className="flex flex-col sm:flex-row w-full sm:w-[60%] justify-start gap-10">
    <h1 className="text-4xl font-bold text-gray-700">{t('register')}</h1>
    <div className="flex flex-row gap-5 items-center">
        {/* Dropdown */}
        <div className="relative flex flex-col gap-2 rounded-2xl">
            <button onClick={() => setDropDown(!dropDown)} id="dropdownDefaultButton" data-dropdown-toggle="dropdown" className={`bg-gray-100 text-black font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center`} type="button">{t(form)} <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                </svg>
            </button>
            <div id="dropdown" className={`z-10 bg-white text-black -right-1 top-12 absolute divide-y divide-gray-100 rounded-lg shadow w-44 ${dropDown ? 'block' : 'hidden'}`}>
                <ul className="py-2 text-sm" aria-labelledby="dropdownDefaultButton">
                    <li onClick={() => { setForm("Individual"); setDropDown(!dropDown); setFormData((prevData) => ({ ...prevData, type: "Individual", })); }}>
                        <a href="#" className="block px-4 py-2 hover:bg-gray-200">{t('Individual')}</a>
                    </li>
                    <li onClick={() => { setForm("Kids Fitness"); setDropDown(!dropDown); setFormData((prevData) => ({ ...prevData, type: "Kids Fitness", })); }}>
                        <a href="#" className="block px-4 py-2 hover:bg-gray-200">{t('Kids Fitness')}</a>
                    </li>
                    <li onClick={() => { setForm("Shkolla e Notit"); setDropDown(!dropDown); setFormData((prevData) => ({ ...prevData, type: "Shkolla e Notit", })); }}>
                        <a href="#" className="block px-4 py-2 hover:bg-gray-200">{t('Shkolla e Notit')}</a>
                    </li>
                    <li onClick={() => { setForm("Rezervime Grupore"); setDropDown(!dropDown); setFormData((prevData) => ({ ...prevData, type: "Rezervime Grupore", })); }}>
                        <a href="#" className="block px-4 py-2 hover:bg-gray-200">{t('Rezervime Grupore')}</a>
                    </li>
                </ul>
            </div>
        </div>

        {/* Emri i Agjentit Input */}
        <div className="flex flex-row max-[400px]:flex-col justify-between w-full gap-0 max-[400px]:gap-2 lg:gap-5">
        <label>{t('collaborator')} </label>
        <input 
                type="text" 
                readOnly
                value={formData.agentName} 
                onChange={handleInputChange} 
                name="agentName" 
                className="border-b-[1px] border-black outline-none"
            />
        </div>
    </div>
</div>
            <div className="flex flex-col w-full gap-10 mt-10 sm:flex-row">
                <div className="flex flex-col w-full sm:w-[55%] text-gray-600 gap-5 min-h-full">
                    <h2 className="ml-0 text-xl font-bold sm:ml-2 xl:ml-5">{t('yourData')}</h2>
                    <div className="flex flex-col h-full gap-2 pl-5 pr-8 lg:pr-10 xl:pr-14 2xl:pr-20 sm:border-r-[1px] sm:border-gray-400">
                        {/* New Form Fields for Group Reservations */}
                        {form === "Rezervime Grupore" && (
                            <>
                                <div className="flex flex-row max-[400px]:flex-col justify-between w-full gap-0 max-[400px]:gap-2 lg:gap-5">
                                    <label>Institucioni</label>
                                    <input type="text" value={formData.institution} onChange={(e) => handleInputChange(e)} name="institution" className="border-b-[1px] border-black outline-none w-[60%] max-[400px]:w-full sm:w-[150px] xl:w-[250px] 2xl:w-[300px]" />
                                </div>
                                <div className="flex flex-row max-[400px]:flex-col justify-between w-full gap-0 max-[400px]:gap-2 lg:gap-5">
                                    <label>Adresa</label>
                                    <input type="text" value={formData.address} onChange={(e) => handleInputChange(e)} name="address" className="border-b-[1px] border-black outline-none w-[60%] max-[400px]:w-full sm:w-[150px] xl:w-[250px] 2xl:w-[300px]" />
                                </div>
                                <div className="flex flex-row max-[400px]:flex-col justify-between w-full gap-0 max-[400px]:gap-2 lg:gap-5">
                                    <label>Email</label>
                                    <input type="email" value={formData.email} onChange={(e) => handleInputChange(e)} name="email" className="border-b-[1px] border-black outline-none w-[60%] max-[400px]:w-full sm:w-[150px] xl:w-[250px] 2xl:w-[300px]" />
                                </div>
                                <div className="flex flex-row max-[400px]:flex-col justify-between w-full gap-0 max-[400px]:gap-2 lg:gap-5">
                                    <label>Telefon</label>
                                    <input type="text" value={formData.phone} onChange={(e) => handleInputChange(e)} name="phone" className="border-b-[1px] border-black outline-none w-[60%] max-[400px]:w-full sm:w-[150px] xl:w-[250px] 2xl:w-[300px]" />
                                </div>
                                <div className="flex flex-row max-[400px]:flex-col justify-between w-full gap-0 max-[400px]:gap-2 lg:gap-5">
                                    <label>Personi Përgjegjës</label>
                                    <input type="text" value={formData.responsiblePerson} onChange={(e) => handleInputChange(e)} name="responsiblePerson" className="border-b-[1px] border-black outline-none w-[60%] max-[400px]:w-full sm:w-[150px] xl:w-[250px] 2xl:w-[300px]" />
                                </div>
                                {additionalDates.map((date, index) => (
                                    <div key={index} className="flex flex-row max-[400px]:flex-col justify-between w-full gap-0 max-[400px]:gap-2 lg:gap-5">
                                        <label>{index === 0 ? "Data" : `Data ${index + 1}`}</label>
                                        <input type="date" value={date} onChange={(e) => handleAdditionalDateChange(index, e.target.value)} name={`date-${index}`} className="border-b-[1px] border-black outline-none w-[60%] max-[400px]:w-full sm:w-[150px] xl:w-[250px] 2xl:w-[300px]" />
                                    </div>
                                ))}
                                <button type="button" onClick={addAdditionalDate} className="px-3 py-1 mt-2 text-gray-700 bg-gray-200 rounded-md">Add Another Date</button>
                                <div className="flex flex-row max-[400px]:flex-col justify-between w-full gap-0 max-[400px]:gap-2 lg:gap-5">
                                    <label>Koha</label>
                                    <input type="string" value={formData.time} onChange={(e) => handleInputChange(e)} name="time" className="border-b-[1px] border-black outline-none w-[60%] max-[400px]:w-full sm:w-[150px] xl:w-[250px] 2xl:w-[300px]" />
                                </div>
                                <div className="flex flex-row max-[400px]:flex-col justify-between w-full gap-0 max-[400px]:gap-2 lg:gap-5">
                                    <label>Numri i Personave</label>
                                    <input type="number" value={formData.numberOfPeople} onChange={(e) => handleInputChange(e)} name="numberOfPeople" className="border-b-[1px] border-black outline-none w-[60%] max-[400px]:w-full sm:w-[150px] xl:w-[250px] 2xl:w-[300px]" />
                                </div>
                                <div className="flex flex-row max-[400px]:flex-col justify-between w-full gap-0 max-[400px]:gap-2 lg:gap-5">
                                    <label>Mosha</label>
                                    <input type="number" value={formData.age} onChange={(e) => handleInputChange(e)} name="age" className="border-b-[1px] border-black outline-none w-[60%] max-[400px]:w-full sm:w-[150px] xl:w-[250px] 2xl:w-[300px]" />
                                </div>
                                <div className="flex flex-row max-[400px]:flex-col justify-between w-full gap-0 max-[400px]:gap-2 lg:gap-5">
                                    <label>Meshkuj</label>
                                    <input type="number" value={formData.male} onChange={(e) => handleInputChange(e)} name="male" className="border-b-[1px] border-black outline-none w-[60%] max-[400px]:w-full sm:w-[150px] xl:w-[250px] 2xl:w-[300px]" />
                                </div>
                                <div className="flex flex-row max-[400px]:flex-col justify-between w-full gap-0 max-[400px]:gap-2 lg:gap-5">
                                    <label>Femra</label>
                                    <input type="number" value={formData.female} onChange={(e) => handleInputChange(e)} name="female" className="border-b-[1px] border-black outline-none w-[60%] max-[400px]:w-full sm:w-[150px] xl:w-[250px] 2xl:w-[300px]" />
                                </div>
                                <div className="flex flex-col gap-2">
                                    <label>Zgjedh Ofertën</label>
                                    <select name="offer" value={formData.offer} onChange={handleInputChange} className="border-b-[1px] border-black outline-none w-full">
                                        {discountData.GroupReservations.options.map((option, index) => (
                                            <option key={index} value={option.label}>{option.label}</option>
                                        ))}
                                    </select>
                                </div>
                            </>
                        )}

                        {form !== "Rezervime Grupore" && (
                            <>
                                <div className="flex flex-row max-[400px]:flex-col justify-between w-full gap-0 max-[400px]:gap-2 lg:gap-5">
                                    <label>{t('first_name')}</label>
                                    <input type="text" value={formData.firstName} onChange={(e) => handleInputChange(e)} name="firstName" className="border-b-[1px] border-black outline-none w-[60%] max-[400px]:w-full sm:w-[150px] xl:w-[250px] 2xl:w-[300px]" />
                                </div>
                                <div className="flex flex-row max-[400px]:flex-col justify-between w-full gap-0 max-[400px]:gap-2 lg:gap-5">
                                    <label>{t('last_name')}</label>
                                    <input type="text" value={formData.lastName} onChange={(e) => handleInputChange(e)} name="lastName" className="border-b-[1px] border-black outline-none w-[60%] max-[400px]:w-full sm:w-[150px] xl:w-[250px] 2xl:w-[300px]" />
                                </div>
                                <div className="flex flex-row max-[400px]:flex-col justify-between w-full gap-0 max-[400px]:gap-2 lg:gap-5">
                                    <label>{t('date_of_birth')}</label>
                                    <input type="date" value={formData.dateOfBirth} onChange={(e) => handleInputChange(e)} name="dateOfBirth" className="border-b-[1px] border-black outline-none w-[60%] max-[400px]:w-full sm:w-[150px] xl:w-[250px] 2xl:w-[300px]" />
                                </div>
                                <div className={`${formData?.type === 'Individual' ? 'hidden' : 'flex'} flex-row max-[400px]:flex-col justify-between w-full gap-0 max-[400px]:gap-2 lg:gap-5`}>
                                    <label>{t('child_age')}</label>
                                    <input type='number' name="age" value={formData.age} onChange={(e) => { handleInputChange(e) }} className="border-b-[1px] border-black outline-none w-[60%] max-[400px]:w-full sm:w-[150px] xl:w-[250px] 2xl:w-[300px]" />
                                </div>
                                <div className="flex flex-row max-[400px]:flex-col justify-between w-full gap-0 max-[400px]:gap-2 lg:gap-5">
                                    <label>{t('address')}</label>
                                    <input type="text" value={formData.address} onChange={(e) => handleInputChange(e)} name="address" className="border-b-[1px] border-black outline-none w-[60%] max-[400px]:w-full sm:w-[150px] xl:w-[250px] 2xl:w-[300px]" />
                                </div>
                                <div className="flex flex-row max-[400px]:flex-col justify-between w-full gap-0 max-[400px]:gap-2 lg:gap-5">
                                    <label>{t('phone')}</label>
                                    <input type="text" value={formData.phone} onChange={(e) => handleInputChange(e)} name="phone" className="border-b-[1px] border-black outline-none w-[60%] max-[400px]:w-full sm:w-[150px] xl:w-[250px] 2xl:w-[300px]" />
                                </div>
                                <div className="flex flex-row max-[400px]:flex-col justify-between w-full gap-0 max-[400px]:gap-2 lg:gap-5">
                                    <label>{t('email')}</label>
                                    <input value={formData.email} type="text" onChange={(e) => handleInputChange(e)} name="email" className="border-b-[1px] border-black outline-none w-[60%] max-[400px]:w-full sm:w-[150px] xl:w-[250px] 2xl:w-[300px]" />
                                </div>
                                <div className={`flex-col w-full mt-5 sm:mt-5 text-gray-600 gap-5`}>
                                    <div className="flex flex-col gap-1">
                                        <div className={`${formData?.type === 'Shkolla Verore' || formData?.type === 'kids fitness' ? 'hidden' : 'flex'} flex-col gap-1`}>
                                            <h3 className="text-xl font-bold">{t('swimming_skills')}</h3>
                                            <label onClick={() => setFormData({ ...formData, swimmingExpertise: 'poor' })} className="flex items-center space-x-2 cursor-pointer">
                                                <span className="w-[100px]">{t('cantSwim')}</span>
                                                <input
                                                    type="radio"
                                                    name="radio1"
                                                    className="hidden peer"
                                                    checked={formData?.swimmingExpertise === 'poor'}
                                                    onChange={() => setFormData({ ...formData, swimmingExpertise: 'poor' })}
                                                />
                                                <div className="w-5 h-5 border-2 border-gray-300 rounded-sm peer-checked:border-blue-500 peer-checked:bg-blue-500"></div>
                                            </label>
                                            <label onClick={() => setFormData({ ...formData, swimmingExpertise: 'average' })} className="flex items-center space-x-2 cursor-pointer">
                                                <span className="w-[100px]">{t('canSwim')}</span>
                                                <input
                                                    type="radio"
                                                    name="radio1"
                                                    className="hidden peer"
                                                    checked={formData?.swimmingExpertise === 'average'}
                                                    onChange={() => setFormData({ ...formData, swimmingExpertise: 'average' })}
                                                />
                                                <div className="w-5 h-5 border-2 border-gray-300 rounded-sm peer-checked:border-blue-500 peer-checked:bg-blue-500"></div>
                                            </label>
                                            <label onClick={() => setFormData({ ...formData, swimmingExpertise: 'good' })} className="flex items-center space-x-2 cursor-pointer">
                                                <span className="w-[100px]">{t('swimWell')}</span>
                                                <input
                                                    type="radio"
                                                    name="radio1"
                                                    className="hidden peer"
                                                    checked={formData?.swimmingExpertise === 'good'}
                                                    onChange={() => setFormData({ ...formData, swimmingExpertise: 'good' })}
                                                />
                                                <div className="w-5 h-5 border-2 border-gray-300 rounded-sm peer-checked:border-blue-500 peer-checked:bg-blue-500"></div>
                                            </label>
                                        </div>

                                        <div className={`flex-col `}>
                                            <div className="pt-3 border-t-[1px] border-gray-400 mt-3 w-full lg:max-w-fit flex flex-row gap-3">
                                                <label>{t('health_problems')} </label>
                                                <div className="flex flex-row gap-3">
                                                    <label onClick={() => setFormData({ ...formData, healthProblems: true })} className="flex items-center space-x-2 cursor-pointer">
                                                        <span>{t('yes')}</span>
                                                        <input
                                                            type="radio"
                                                            name="radio2"
                                                            className="hidden peer"
                                                            checked={formData?.healthProblems === true}
                                                            onChange={() => setFormData({ ...formData, healthProblems: true })}
                                                        />
                                                        <div className="w-5 h-5 border-2 border-gray-300 rounded-sm peer-checked:border-blue-500 peer-checked:bg-blue-500"></div>
                                                    </label>
                                                    <label onClick={() => setFormData({ ...formData, healthProblems: false })} className="flex items-center space-x-2 cursor-pointer">
                                                        <span>{t('no')}</span>
                                                        <input
                                                            type="radio"
                                                            name="radio2"
                                                            className="hidden peer"
                                                            checked={formData?.healthProblems === false}
                                                            onChange={() => setFormData({ ...formData, healthProblems: false })}
                                                        />
                                                        <div className="w-5 h-5 border-2 border-gray-300 rounded-sm peer-checked:border-blue-500 peer-checked:bg-blue-500"></div>
                                                    </label>
                                                </div>
                                            </div>
                                            <p className="text-xs text-gray-400">{t('describe_health_problems')}</p>
                                        </div>
                                    </div>
                                    <input onChange={(e) => setFormData({ ...formData, healthProblemDescription: e.target.value })} className="sm:w-[90%] w-[90%] border-b-[1px] border-black" />
                                    <input className="sm:w-[90%] w-[90%] border-b-[1px] border-black" />

                                    <div className={`${formData.type === 'kids fitness' ? 'hidden' : 'flex'} flex-col gap-2 flex-wrap`}>
                                        <div className='flex flex-row gap-5 mt-5'>
                                            {registrations && registrations.map((registration, index) => (
                                                <div className='flex cursor-pointer flex-row rounded-2xl p-1 px-2 gap-5 items-center border-2 border-[#0062FF]' >
                                                    <span onClick={() => setFormData(registration)} className='text-[#0062FF]'>{registration.firstName} {registration.lastName}</span>
                                                    <img src={Cross} onClick={() => handleRemoveRegistration(index)} className='w-4 h-4 ' />
                                                </div>
                                            ))}
                                        </div>

                                        <div>
                                            <button
                                                onClick={(e) => {
                                                    if (isDuplicateRegistration()) {
                                                        toast.error(t('duplicate_member'));
                                                    } else {
                                                        toast.success(t('member_added'));
                                                        handleSubmit(e);
                                                    }
                                                }} className={`flex flex-row items-center justify-center mt-5 rounded-md border-2 border-[#0062FF] text-[#0062FF] py-1 px-5 gap-2 ${isDuplicateRegistration() ? 'opacity-50 cursor-not-allowed' : 'hover:bg-[#0062FF] hover:text-white'}`}>
                                                {t('add_member')}
                                                <img src={Plus} className='w-5 h-5' />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>

                <div className={`flex-col gap-10 ${form === 'Individual' ? 'flex' : 'hidden'}`}>
                    <div className="flex flex-col gap-5">
                        <h2 className="text-2xl font-bold">{t('individual_membership_basic')}</h2>
                        <IndividualDiscountCard formData={formData} setFormData={setFormData} discountData={discountData.Individual.basic} />
                    </div>
                    <div className="flex flex-col gap-5">
                        <h2 className="text-2xl font-bold">{t('individual_membership_premium')}</h2>
                        <IndividualDiscountCard formData={formData} setFormData={setFormData} discountData={discountData.Individual.premium} />
                    </div>
                </div>
                <div className={`flex-col gap-10 ${form === 'Kids Fitness' ? 'flex' : 'hidden'}`}>
                    <div className="flex flex-col gap-5">
                        <h2 className="text-2xl font-bold">{t('kids_fitness')}</h2>
                        <FitnessKidDiscountCard formData={formData} setFormData={setFormData} discountData={discountData.Individual.basic} />
                    </div>
                </div>
                <div className={`flex-col gap-3 ${form === 'Shkolla e Notit' ? 'flex' : 'hidden'}`}>
                    <div className="flex flex-col gap-1">
                        <h2 className="text-2xl font-bold">{t('swimming_school_infants')}</h2>
                        <TeamDiscountCard formData={formData} setFormData={setFormData} discountData={discountData.Team.Infants} ageGroup="infants" ageStart={3} ageEnd={4} />
                    </div>
                    <div className="flex flex-col gap-1">
                        <h2 className="text-2xl font-bold">{t('swimming_school_children')}</h2>
                        <TeamDiscountCard formData={formData} setFormData={setFormData} discountData={discountData.Team.Children} ageGroup="children" ageStart={5} ageEnd={17} />
                    </div>
                    <div className="flex flex-col gap-1">
                        <h2 className="text-2xl font-bold">{t('swimming_school_adults')}</h2>
                        <TeamDiscountCard formData={formData} setFormData={setFormData} discountData={discountData.Team.Adults} ageGroup="adult" ageStart={18} ageEnd={100} />
                    </div>
                </div>
                <div className={`flex-col gap-3 ${form === 'Rezervime Grupore' ? 'flex' : 'hidden'} items-center justify-center`}>
                    <div className="p-6 text-center text-white border rounded-lg shadow-lg bg-gradient-to-r from-blue-400 to-blue-600 w-80">
                        <h2 className="mb-2 text-2xl font-bold">{t('total_amount')}</h2>
                        <p className="text-3xl font-semibold">{`${calculateTotalAmount()}€`}</p>
                    </div>
                </div>
            </div>
            <div className="flex flex-col justify-between gap-0 sm:gap-5 lg:gap-10 sm:flex-row">
                <div className="flex flex-col items-end justify-end w-full gap-2 mt-5 sm:mt-20">
                <button
                        onClick={(e) => {
                            if (!checkbox && form !== "Rezervime Grupore") {
                                toast.warning('Nuk jeni pajtuar me kushtet e kontratës!');
                                return;
                            }

                            if (form === "Rezervime Grupore") {
                                handleGroupReservationSubmit(e);
                            } else {
                                if (registrations.length === 0) {
                                    if (formData.healthProblems === true && formData.healthProblemDescription === "") {
                                        toast.warning('Ju lutemi përshkruani problemet shëndetësore');
                                    } else if (formData.time === 0) {
                                        toast.warning('Ju lutemi zgjidhni një plan');
                                    } else if (formData.type === 'Individual' && (!formData.firstName || !formData.lastName || !formData.dateOfBirth || !formData.address || !formData.email || !formData.phone || formData.healthProblems == null || !formData.swimmingExpertise)) {
                                        toast.warning('Ju lutemi plotësoni të gjitha fushat');
                                    } else if (formData.type === 'kids fitness' && (!formData.firstName || !formData.lastName || !formData.dateOfBirth || !formData.address || !formData.email || !formData.phone || formData.healthProblems == null)) {
                                        toast.warning('Ju lutemi plotësoni të gjitha fushat');
                                    } else if (formData.type === 'Shkolla e Notit' && (!formData.firstName || !formData.lastName || !formData.dateOfBirth || !formData.address || !formData.email || !formData.phone || formData.healthProblems == null || !formData.swimmingExpertise)) {
                                        toast.warning('Ju lutemi plotësoni të gjitha fushat');
                                    } else if (formData.type === 'Shkolla e Notit' && formData.age < 3) {
                                        toast.warning('Mosha e fëmijës duhet të jetë mbi 3 vjeç');
                                    } else {
                                        handleRegister();
                                        setStep(2);
                                    }
                                } else {
                                    handleRegister();
                                    setStep(2);
                                }
                            }
                        }}
                        className={`px-10 py-2 font-medium text-gray-500 transition-all duration-200 rounded-lg max-w-fit ${checkbox === false ? 'opacity-50' : 'opacity-100 hover:bg-[#0062FF] hover:text-white duration-300 transition-all'}`}
                    >
                        {form === "Rezervime Grupore" ? 'Dërgo Kërkesën' : 'Regjistrohu'}
                    </button>
                    <div className="flex flex-row gap-2">
                        <label>{t('agree_terms')} <span className="text-blue-600">{t('terms_conditions')}</span></label>
                        <input onClick={() => { setCheckbox(!checkbox) }} type="checkbox" name="" className="w-5 bg-gray-300" />
                    </div>
                </div>
            </div>
        </div>
    );
}
