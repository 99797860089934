import { useEffect, useRef, useState } from "react";
import logo from '../assets/images/Logo-step.png';
import LanguageSelector from "./languageSelector";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import Avatar from 'react-avatar';
import { useAuth } from '../AuthContext'; // Import the useAuth hook
import { FaFacebookF, FaInstagram, FaLink } from 'react-icons/fa';

export const Navbar = () => {
    const menuRef = useRef(null);
    const btnRef = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { t, i18n } = useTranslation();
    const { user, loggedIn } = useAuth(); // Use the useAuth hook
    const location = useLocation();

    const [current, setCurrent] = useState('home');
    const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
    const [visible, setVisible] = useState(true);

    const openMenu = () => {
        setIsMenuOpen(prevState => !prevState);
    }

    const closeMenu = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target) && !btnRef.current.contains(event.target)) {
            setIsMenuOpen(false);
        }
    }

    const handleScroll = () => {
        const currentScrollPos = window.pageYOffset;
        setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
        setPrevScrollPos(currentScrollPos);
    };

    useEffect(() => {
        document.addEventListener("mousedown", closeMenu);
        window.addEventListener("scroll", handleScroll);

        return () => {
            document.removeEventListener("mousedown", closeMenu);
            window.removeEventListener("scroll", handleScroll);
        };
    }, [prevScrollPos, visible]);

    useEffect(() => {
        // Scroll to top on route change
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <nav className="border-gray-200 font-custom2 h-20 dark:bg-gray-900 fixed top-0 left-0 min-w-[100vw] z-50" style={{ backgroundColor: '#1f2937' }}>
            <div className="flex flex-wrap items-center justify-between p-4 mx-auto max-w-screen-2xl">
                <Link onClick={() => {
                    setCurrent('home');
                    setIsMenuOpen(false); // Close menu on link click
                }} to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                    <img src={logo} className="h-12" alt="Flowbite Logo" />
                </Link>
                <button 
                    data-collapse-toggle="navbar-default" 
                    type="button" 
                    onClick={openMenu} 
                    ref={btnRef}
                    className="inline-flex items-center justify-center w-10 h-10 p-2 text-sm text-white rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                    aria-controls="navbar-default" 
                    aria-expanded={isMenuOpen}>
                    <span className="sr-only">Open main menu</span>
                    {isMenuOpen ? (
                        <svg className="w-5 h-5 transform transition-transform duration-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white" >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    ) : (
                        <svg className="w-5 h-5 transform transition-transform duration-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="white"
                            viewBox="0 0 17 14">
                            <path stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                d="M1 1h15M1 7h15M1 13h15" />
                        </svg>
                    )}
                </button>
                <div className="hidden md:flex items-center space-x-8">
                    <Link to="/" onClick={() => setCurrent('home')} className={`px-3 py-2 rounded hover:text-primaryBlue ${current === 'home' ? 'text-primaryBlue' : 'text-white'}`}>{t('home')}</Link>
                    <Link to="/info" onClick={() => setCurrent('info')} className={`px-3 py-2 rounded hover:text-primaryBlue ${current === 'info' ? 'text-primaryBlue' : 'text-white'}`}>{t("around")}</Link>
                    <Link to="/staff" onClick={() => setCurrent('staff')} className={`px-3 py-2 rounded hover:text-primaryBlue ${current === 'staff' ? 'text-primaryBlue' : 'text-white'}`}>{t("staff")}</Link>
                    <Link to="/registration" onClick={() => setCurrent('registration')} className={`px-3 py-2 rounded hover:text-primaryBlue ${current === 'registration' ? 'text-primaryBlue' : 'text-white'}`}>{t("register")}</Link>
                    {loggedIn ? (
                        <Link to="/profile" className={`px-3 py-2 rounded hover:text-primaryBlue ${current === 'profile' ? 'text-primaryBlue' : 'text-white'}`}>
                            <Avatar name={user.email} size="30" round={true} />
                        </Link>
                    ) : (
                        <Link to="/login" onClick={() => setCurrent('login')} className={`px-3 py-2 rounded hover:text-primaryBlue ${current === 'login' ? 'text-primaryBlue' : 'text-white'}`}>{t("login")}</Link>
                    )}
                    <LanguageSelector />
                </div>
                <div 
                    className={`w-full fixed top-0 left-0 min-h-screen bg-gray-900 md:hidden transition-transform duration-500 ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'}`} 
                    id="navbar-default"
                    ref={menuRef}>
                    <button 
                        type="button" 
                        onClick={openMenu}
                        className="absolute top-4 right-4 inline-flex items-center justify-center w-10 h-10 p-2 text-sm text-white rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                        <span className="sr-only">Close main menu</span>
                        <svg className="w-5 h-5 transform transition-transform duration-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                    <div className="flex flex-col items-center mt-10">
                        <Link to="/" onClick={() => setIsMenuOpen(false)}>
                            <img src={logo} className="h-12 mb-4" alt="Flowbite Logo" />
                        </Link>
                        <ul className="flex flex-col items-center h-full p-4 font-medium">
                            <li className="mb-4">
                                <Link to="/" onClick={() => { setCurrent('home'); setIsMenuOpen(false); }} className={`block px-3 py-2 rounded hover:text-primaryBlue ${current === 'home' ? 'text-primaryBlue' : 'text-white'}`} aria-current="page">{t('home')}</Link>
                            </li>
                            <li className="mb-4" onClick={() => { setCurrent('info'); setIsMenuOpen(false); }}>
                                <Link to="/info" className={`block px-3 py-2 rounded hover:text-primaryBlue ${current === 'info' ? 'text-primaryBlue' : 'text-white'}`}>{t("around")}</Link>
                            </li>
                            <li className="mb-4" onClick={() => { setCurrent('staff'); setIsMenuOpen(false); }}>
                                <Link to="/staff" className={`block px-3 py-2 rounded hover:text-primaryBlue ${current === 'staff' ? 'text-primaryBlue' : 'text-white'}`}>{t("staff")}</Link>
                            </li>
                            <li className="mb-4" onClick={() => { setCurrent('registration'); setIsMenuOpen(false); }}>
                                <Link to="/registration" className={`block px-3 py-2 rounded hover:text-primaryBlue ${current === 'registration' ? 'text-primaryBlue' : 'text-white'}`}>{t("register")}</Link>
                            </li>
                            {loggedIn ? (
                                <li className="mb-4">
                                    <Link to="/profile" onClick={() => setIsMenuOpen(false)} className={`block px-3 py-2 rounded hover:text-primaryBlue ${current === 'profile' ? 'text-primaryBlue' : 'text-white'}`}>
                                        <Avatar name={user.email} size="30" round={true} />
                                    </Link>
                                </li>
                            ) : (
                                <li className="mb-4" onClick={() => { setCurrent('login'); setIsMenuOpen(false); }}>
                                    <Link to="/login" className={`block px-3 py-2 rounded hover:text-primaryBlue ${current === 'login' ? 'text-primaryBlue' : 'text-white'}`}>{t("login")}</Link>
                                </li>
                            )}
                            <li className="mb-4">
                                <LanguageSelector />
                            </li>
                            <li className="mb-4">
                                {/* Social media icons */}
                                <div className="flex space-x-4">
                                    <a href="https://www.facebook.com/profile.php?id=100077376647582&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer" className="text-white hover:text-blue-500">
                                        <FaFacebookF className="w-5 h-5" />
                                    </a>
                                    <a href="https://www.instagram.com/stepsportcenter?igsh=eGk4d2Z0NmYyZTdo" target="_blank" rel="noopener noreferrer" className="text-white hover:text-pink-500">
                                        <FaInstagram className="w-5 h-5" />
                                    </a>
                                    <a href="https://linktr.ee/StepSportCenter?fbclid=PAZXh0bgNhZW0CMTEAAab-jMdOYXcTDUid0g5hQfCGeMuItxrQydbTRx0Og3ybI17Y3OGw3bkMCnk_aem_TZIZ2U6MaIXwcKeEsavbyg" target="_blank" rel="noopener noreferrer" className="text-white hover:text-blue-500">
                                        <FaLink className="w-5 h-5" />
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    )
}
