import bg from "../assets/images/home-bg.png";
import { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import randn from "randn";

export const Signup = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        confirm: ''
    });
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [otp, setOtp] = useState('');
    const [otpField, setOtpField] = useState('');
    const { t } = useTranslation();

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleContinue = () => {
        if (!validateEmail(formData.email)) {
            toast.error(t('invalid_email'));
            return;
        }
        setStep(2);
    };

    const handleRegister = () => {
        if (formData.password !== formData.confirm) {
            toast.error(t('passwords_do_not_match'));
            return;
        }
        setStep(3);
    };

    const sendOtp = async () => {
        try {
            const newOtp = randn(5);
            setOtp(newOtp);
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/sendOtp`, {
                email: formData.email,
                otpCode: newOtp
            });
            if (response.status === 200) {
                toast.success(t('otp_sent'));
            } else {
                throw new Error('Unexpected response status');
            }
        } catch (error) {
            console.error('Error sending OTP:', error);
            toast.error(t('failed_to_send_otp'));
        }
    };

    const signup = async () => {
        if (otpField !== otp) {
            toast.error(t('otp_does_not_match'));
            return;
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/register`, formData);
            if (response.status === 200) {
                navigate('/login');
            }
        } catch (error) {
            toast.error(t('write_otp_code'));
        }
    };

    return (
        <div className="mt-20 font-custom2">
            <div className="flex flex-col items-center justify-center px-20 md:min-h-screen md:max-h-screen">
                <img src={bg} alt="bg" className="hidden min-w-[100vw] min-h-[30vh] md:min-h-[100vh] md:block absolute top-0 left-0 -z-10" />
                <div className='mt-10 sm:mt-20 md:mt-0 max-w-[95vw] bg-white p-10 rounded-lg shadow-lg flex flex-col items-center justify-center gap-7'>
                    <h1 className="self-start text-2xl font-bold">{t('signup')}</h1>
                    <div className="flex flex-col gap-5">
                        {step === 1 && (
                            <div className="flex flex-col w-72">
                                <label className="text-sm">{t('email')}</label>
                                <input 
                                    className="p-2 rounded-lg bg-gray-100" 
                                    type="text" 
                                    placeholder={t('email')}
                                    value={formData.email}
                                    onChange={(e) => setFormData({...formData, email: e.target.value})}
                                />
                            </div>
                        )}
                        {step === 2 && (
                            <>
                                <div className="flex flex-col">
                                    <label className="text-sm">{t('password')}</label>
                                    <input 
                                        className="p-2 rounded-lg bg-gray-100" 
                                        type="password" 
                                        placeholder={t('password')}
                                        value={formData.password}
                                        onChange={(e) => setFormData({...formData, password: e.target.value})}
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <label className="text-sm">{t('confirm_password')}</label>
                                    <input 
                                        className="p-2 rounded-lg bg-gray-100" 
                                        type="password"
                                        placeholder={t('confirm_password')}
                                        value={formData.confirm}
                                        onChange={(e) => setFormData({...formData, confirm: e.target.value})}
                                    />
                                </div>
                            </>
                        )}
                        {step === 3 && (
                            <div className="flex flex-col w-72">
                                <label className="text-sm">{t('otp')}</label>
                                <input 
                                    className="p-2 rounded-lg bg-gray-100" 
                                    type="text" 
                                    placeholder={t('otp')}
                                    value={otpField}
                                    onChange={(e) => setOtpField(e.target.value)}
                                />
                            </div>
                        )}
                    </div>

                    <div className="min-w-full flex flex-col justify-center gap-2">
                        {step === 1 && (
                            <button className="min-w-full bg-blue-400 p-2 rounded-lg text-white" onClick={handleContinue}>
                                {t('continue')}
                            </button>
                        )}
                        {step === 2 && (
                            <button className="min-w-full bg-blue-400 p-2 rounded-lg text-white" onClick={handleRegister}>
                                {t('register')}
                            </button>
                        )}
                        {step === 3 && (
                            <>
                                <button className="min-w-full bg-blue-400 p-2 rounded-lg text-white" onClick={sendOtp}>
                                    {t('sendOTP')}
                                </button>
                                <button className="min-w-full bg-green-400 p-2 rounded-lg text-white" onClick={signup}>
                                    {t('signup')}
                                </button>
                            </>
                        )}
                        <a className="text-xs mt-2 text-center self-center" href="/login">{t('already_have_account')}</a>
                    </div>
                </div>
            </div>
        </div>
    );
};