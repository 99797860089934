import React from "react";
import blueBg from "../assets/images/blue-bg.png";
import blueLines from "../assets/images/blue-lines.png";
import staff01 from "../assets/images/11.jpg";
import staff02 from "../assets/images/staff02.png";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './staff.css';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import staff03 from "../assets/images/2.jpg";
import staff04 from "../assets/images/3.jpg";
import staff05 from "../assets/images/4.jpg";
import staff06 from "../assets/images/5.jpg";
import staff07 from "../assets/images/6.jpg";
import staff08 from "../assets/images/7.jpg";
import staff09 from "../assets/images/8.jpg";
import staff10 from "../assets/images/9.jpg";
import staff11 from "../assets/images/10.jpg";
import { useTranslation } from "react-i18next";

const data = [
    {
        name: "Mendim Samadraxha",
        detail1: "Pishina 25m",
        detail2: "Grupmosha 5+",
        image: staff03
    },
    {
        name: "Erjon Shurdhaj",
        detail1: "Pishina 15m",
        detail2: "Grupmoshat 3&4",
        image: staff04
    },
    {
        name: "Rina Zuka",
        detail1: "Pishina 15m",
        detail2: "Grupmoshat 3&4",
        image: staff05
    },
    {
        name: "Hana Fejzullahu",
        detail1: "Trajnere e akademisë së notit",
        image: staff06
    },
    {
        name: "Elona Toverlani",
        detail1: "Pishina 25m",
        detail2: "Grupmosha 5+",
        image: staff07
    },
    {
        name: "Erjon Buzhala",
        detail1: "Pishina 15m",
        detail2: "Grupmosha 5+",
        image: staff08
    },
    {
        name: "Artiol Shala",
        detail1: "Pishina 25m",
        detail2: "Grupmosha 5+",
        image: staff09
    },
    {
        name: "Dren Nitaj",
        detail1: "Pishina 15m",
        detail2: "Grupmosha 5+",
        image: staff10
    },
    {
        name: "Korabi",
        detail1: "Pishina 15m",
        detail2: "Grupmosha 5+",
        image: staff11
    },
]

export const Staff = () => {
    const { t } = useTranslation();

    return (
        <div className="overflow-x-hidden overflow-y-hidden font-custom2">
            <div className="md:min-h-[82vh] flex flex-col md:flex-col bg-transparent md:pb-20 text-center gap-20 py-32 relative">
                <h1 className="px-5 text-4xl font-bold text-gray-400 mb-10 text-4xl font-bold text-gray-700">{t("meet_our_trainers")}</h1>
                <div className="relative flex flex-col items-center justify-center w-full gap-10 px-5 overflow-visible overflow-x-hidden md:flex-row">
                    <div className="flex flex-col items-center justify-center p-5 bg-white rounded-2xl w-fit">
                        <img src={staff01} className="w-[395px] h-auto rounded-2xl" />
                        <div className="flex flex-col items-center text-center text-[#2B497B]">
                            <p className="px-5 text-xl text-sm sm:text-base lg:text-lg">Rita Zeqiri</p>
                            <p className="px-5 text-sm sm:text-base lg:text-lg">{t("swimming_trainer")}</p>
                        </div>
                    </div>
                   <div className="flex flex-col bg-transparent text-start min-w-[200px] max-w-[500px] h-full items-center justify-center">
  <p className="text-sm sm:text-base lg:text-lg">{t("rita_description1")}</p>
  <br />
  <p className="text-sm sm:text-base lg:text-lg">{t("rita_description2")}</p>
  <br />
  <p className="text-sm sm:text-base lg:text-lg font-bold">{t("rita_description3")}</p>
<ul className="list-none">
  <li className="flex items-start mb-2">
    <span className="mr-2 text-lg text-500">➤</span>
    <p className="text-sm sm:text-base lg:text-lg">{t("rita_description4")}</p>
  </li>
  <li className="flex items-start mb-2">
    <span className="mr-2 text-lg text-500">➤</span>
    <p className="text-sm sm:text-base lg:text-lg">{t("rita_description5")}</p>
  </li>
  <li className="flex items-start mb-2">
    <span className="mr-2 text-lg text-500">➤</span>
    <p className="text-sm sm:text-base lg:text-lg">{t("rita_description6")}</p>
  </li>
  <li className="flex items-start mb-2">
    <span className="mr-2 text-lg text-500">➤</span>
    <p className="text-sm sm:text-base lg:text-lg">{t("rita_description7")}</p>
  </li>
</ul>

</div>

                </div>

                <img src={blueBg} alt="blue bg"
                    className="min-w-[100vw] min-h-[40vh] max-h-[40vh] absolute top-96 left-0 -z-10" />
                <img src={blueLines} alt="blue bg"
                    className="min-w-[100vw] min-h-[40vh] max-h-[40vh] absolute top-96 left-0 -z-10" />

                <div className="relative flex flex-col items-center justify-center w-full gap-10 px-5 overflow-visible md:flex-row">
                    <div className="flex flex-col items-center justify-center p-5 bg-white md:hidden rounded-2xl w-fit">
                        <img src={staff02} className="w-[395px] h-auto rounded-2xl" />
                        <div className="flex flex-col items-center text-center text-[#2B497B]">
                            <p className="text-xl text-sm sm:text-base lg:text-lg">Besart Shala</p>
                            <p className="text-sm sm:text-base lg:text-lg">{t("swimming_trainer")}</p>
                        </div>
                    </div>
                    <div className="flex flex-col bg-transparent text-start min-w-[200px] max-w-[500px] h-full items-center justify-center">
                        <p className="text-sm sm:text-base lg:text-lg">{t("besart_description")}</p>
                    </div>
                    <div className="flex-col items-center justify-center hidden p-5 bg-white md:flex rounded-2xl w-fit">
                        <img src={staff02} className="w-[395px] h-auto rounded-2xl" />
                        <div className="flex flex-col items-center text-center text-[#2B497B]">
                            <p className="text-xl text-sm sm:text-base lg:text-lg">Besart Shala</p>
                            <p className="text-sm sm:text-base lg:text-lg">{t("swimming_trainer_children")}</p>
                            <p className="text-sm sm:text-base lg:text-lg">{t("age_group_10_16")}</p>
                        </div>
                    </div>

                    <img src={blueBg} alt="blue bg"
                        className="min-w-[100vw] min-h-[40vh] max-h-[40vh] absolute -bottom-60 left-0 -z-10" />
                    <img src={blueLines} alt="blue bg"
                        className="min-w-[100vw] min-h-[40vh] max-h-[40vh] absolute -bottom-60 left-0 -z-10" />
                </div>

                <div className="min-w-screen w-[100vw] px-10">
                    <Swiper
                        slidesPerView={5}
                        spaceBetween={20}
                        breakpoints={{
                            400: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 4,
                                spaceBetween: 20,
                            },
                            1024: {
                                slidesPerView: 5,
                                spaceBetween: 20,
                            },
                        }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        modules={[Autoplay]}
                        className="mySwiper"
                    >
                        {
                            data.map((member, index) => (
                                <SwiperSlide key={index}>
                                    <div className='flex flex-col items-center justify-center gap-2 p-5 text-black bg-white rounded-2xl min-h-fit min-w-fit'>
                                        <img src={member.image} alt="trophy" className="w-[250px]" />
                                        <div className='flex flex-col items-center justify-center w-full gap-1 italic text-center'>
                                            <span className='text-2xl text-sm sm:text-base lg:text-lg'>{member.name}</span>
                                            <span className='text-lg text-sm sm:text-base lg:text-lg'>{member.detail1}</span>
                                            <span className='text-lg text-sm sm:text-base lg:text-lg'>{member.detail2}</span>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </div>
            </div>
        </div>
    )
}
