import React, { useState, useEffect } from "react";
import {useTranslation} from "react-i18next";

export const TeamDiscountCard = ({ formData, setFormData, discountData, ageGroup, ageStart, ageEnd }) => {
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const [dropDown, setDropDown] = useState(false);
    const [dropDown2, setDropDown2] = useState(false);
    const [dropDown3, setDropDown3] = useState(false);
    const [entries, setEntries] = useState(discountData.oneMonth[0]);
    const [selectedMonth, setSelectedMonth] = useState(1);
    const [monthData, setMonthData] = useState(discountData.oneMonth);
    const [gender, setGender] = useState("Femra(Avancuar)");

    // const discountedCost = Math.ceil(entries.cost * (1 - (entries.discount / 100)));

    const [plan, setPlan] = useState(false)

    const {t} = useTranslation()

    const handleChosePlan = () => {
        setFormData((prevData) => ({...prevData, time: selectedMonth}));
        setFormData((prevData) => ({...prevData, discount: entries.discount,}));
        setFormData((prevData) => ({...prevData, totalAmount: entries.cost,}));   
        // setFormData((prevData) => ({...prevData, payableAmount: discountedCost}));   
        setFormData((prevData) => ({...prevData, payableAmount: entries.cost}));   
        setFormData((prevData) => ({...prevData, entries: entries.entries}));   
        setFormData((prevData) => ({...prevData, ageGroup: ageGroup}));   
        setFormData((prevData) => ({...prevData, gender: gender}));   
    }

    const isSelected = () => {
        if (formData.time == selectedMonth && formData.discount == entries.discount && formData.totalAmount == entries.cost
            && formData.entries == entries.entries && formData.ageGroup == ageGroup)
        {
            return true;
        } else {
            return false;
        }
    }

    const [disabled , setDisabled] = useState(false)

    useEffect(() => {
        if (formData.age > ageStart-1 && formData.age < ageEnd+1) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [formData.age]);

    return (
        <div className="relative">
            <div className={`absolute inset-0 bg-white bg-opacity-50 z-10 ${disabled ? 'block' : 'hidden'} `}></div>
            <div className={`flex flex-col gap-2 border-[#003DFE] border-2 rounded-lg min-w-[300px] px-5 py-2 max-h-[300px] h-fit font-custom2`}>
                <div className="flex flex-col gap-2 rounded-2xl relative w-[200px]">
                    <button
                        onClick={() => setDropDown(!dropDown)}
                        id="dropdownDefaultButton"
                        data-dropdown-toggle="dropdown"
                        className="text-blue-700 bg-[#D5DFFF] bg-opacity-60 font-medium rounded-lg text-sm px-5 py-1.5 text-center inline-flex items-center"
                        type="button"
                    >
                        {selectedMonth} {t('month')}
                        <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                        </svg>
                    </button>

                    <div id="dropdown" className={`z-10 bg-white text-black -right-1 top-10 absolute divide-y divide-gray-100 rounded-lg shadow w-44 ${dropDown ? 'block' : 'hidden'}`}>
                        <ul className="py-2 text-sm" aria-labelledby="dropdownDefaultButton">
                            <li>
                                <a
                                    onClick={() => { setSelectedMonth(1); setMonthData(discountData.oneMonth); setEntries(discountData.oneMonth[0]); setDropDown(false); }}
                                    href="#"
                                    className="block px-4 py-2 hover:bg-gray-200"
                                >
                                    1 {t('month')}
                                </a>
                            </li>
                            <li>
                                <a
                                    onClick={() => { setSelectedMonth(3); setMonthData(discountData.threeMonths); setEntries(discountData.threeMonths[0]); setDropDown(false); }}
                                    href="#"
                                    className="block px-4 py-2 hover:bg-gray-200"
                                >
                                    3 {t('month')}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="flex flex-row w-full gap-2 text-xs">
                    <span className="p-1 px-3 text-blue-700">Koha: </span>
                    <div className="relative flex flex-col w-full gap-2 rounded-2xl">
                        <button
                            onClick={() => setDropDown2(!dropDown2)}
                            id="dropdownDefaultButton"
                            data-dropdown-toggle="dropdown"
                            className="text-blue-700 bg-[#D5DFFF] bg-opacity-60 rounded-lg text-xs px-5 py-1.5 text-center inline-flex items-center"
                            type="button"
                        >
                            {entries.entries}
                            <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                            </svg>
                        </button>

                        <div id="dropdown" className={`z-50 bg-white text-black -right-1 top-10 absolute divide-y divide-gray-100 rounded-lg shadow w-44 ${dropDown2 ? 'block' : 'hidden'}`}>
                            <ul className="py-2 text-sm" aria-labelledby="dropdownDefaultButton">
                                {
                                    monthData?.map((month, index) => (
                                        <li key={index}>
                                            <a
                                                onClick={() => { setEntries(month); setDropDown2(false); }}
                                                href="#"
                                                className="block px-4 py-2 hover:bg-gray-200"
                                            >
                                                {month.entries}
                                            </a>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={`flex-row w-full gap-2 text-xs ${ageStart > 17 ? 'flex' : 'hidden'}`}>
                    <div className="relative flex flex-col w-fit gap-2 rounded-2xl">
                        <button
                            onClick={() => setDropDown3(!dropDown3)}
                            id="dropdownDefaultButton"
                            data-dropdown-toggle="dropdown"
                            className="text-blue-700 bg-[#D5DFFF] w-fit bg-opacity-60 rounded-lg text-xs px-5 py-1.5 text-center inline-flex items-center"
                            type="button"
                        >
                            {gender}
                            <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                            </svg>
                        </button>

                        <div id="dropdown" className={`z-10 bg-white text-black -right-1 top-10 absolute divide-y divide-gray-100 rounded-lg shadow w-44 ${dropDown3 ? 'block' : 'hidden'}`}>
                            <ul className="py-2 text-sm" aria-labelledby="dropdownDefaultButton">
                                <li>
                                    <a
                                        onClick={() => { setGender("Femra(Avancuar)"); setDropDown3(false); }}
                                        href="#"
                                        className="block px-4 py-2 hover:bg-gray-200"
                                    >
                                        Femra(Avancuar)
                                    </a>
                                </li>
                                <li>
                                    <a
                                        onClick={() => { setGender("Meshkujt (Fillestar&Avancuar)"); setDropDown3(false); }}
                                        href="#"
                                        className="block px-4 py-2 hover:bg-gray-200"
                                    >
                                        Meshkujt (Fillestar&Avancuar)
                                    </a>
                                </li>
                                <li>
                                    <a
                                        onClick={() => { setGender("Meshkuj & Femra (Fillestar)"); setDropDown3(false); }}
                                        href="#"
                                        className="block px-4 py-2 hover:bg-gray-200"
                                    >
                                        Meshkuj & Femra (Fillestar)
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-3">
                    <div className="flex flex-row items-center gap-5 pl-3">
                        <span>{entries.cost}€</span>
                        {/* <span className="p-1 px-3 rounded-2xl bg-[#D5DFFF] bg-opacity-60 text-blue-700">{entries.discount}% {t('off')}</span> */}
                        {/* <span>{discountedCost}€</span> */}
                    </div>
                    <button onClick={() => {setPlan(!plan); handleChosePlan()}} className={`min-w-full border-[#003DFE] font-bold border-2 rounded-lg p-1 hover:bg-[#003DFE] hover:text-white transition-all duration-300 ${isSelected() ? 'bg-[#003DFE] text-white' : 'text-[#003DFE]'} `}>{
                        isSelected() ? t('unChoosePlan') : t('choosePlan')
                    }</button>
                </div>
            </div>
        </div>
    );
};
