import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './roundCarousal.css';

// import required modules
import { Autoplay } from 'swiper/modules';

import staff03 from "../assets/images/2.jpg"
import staff04 from "../assets/images/3.jpg"
import staff05 from "../assets/images/4.jpg"
import staff06 from "../assets/images/5.jpg"
import staff07 from "../assets/images/6.jpg"
import staff08 from "../assets/images/7.jpg"
import staff09 from "../assets/images/8.jpg"
import staff10 from "../assets/images/9.jpg"


const data = [
    {
        image: staff03
    },
    {
        image: staff04
    },
  
    {
        image: staff05
    },
    {
        image: staff06
    },
    {
        image: staff07
    },
    {
        image: staff08
    },
    {
        image: staff09
    },
    {
        image: staff10
    },
    {
      image: staff03
  },
  {
      image: staff04
  },

  {
      image: staff05
  },
  {
      image: staff06
  },
  {
      image: staff07
  },
  {
      image: staff08
  },
  {
      image: staff09
  },
  {
      image: staff10
  },
  {
    image: staff03
},
{
    image: staff04
},

{
    image: staff05
},
{
    image: staff06
},
{
    image: staff07
},
{
    image: staff08
},
{
    image: staff09
},
{
    image: staff10
},
]

const RoundCarousal = () => {
    return (
        <>
        <Swiper
            slidesPerView={1}
            spaceBetween={20}
            loop={true}
            autoplay={{
            delay: 1000,
            disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            breakpoints={{
                400: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                640: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 7,
                  spaceBetween: 20,
                },
                1400: {
                  slidesPerView: 9,
                  spaceBetween: 20,
                },
            }}
            className="mySwiper"
        >
            {
                data.map((member) => (
                    <SwiperSlide>
                        <div className='font-custom2'>
                            <img src={member.image
                            } alt="trophy" className="max-w-[150px] rounded-full"/>
                        </div>
                    </SwiperSlide>
                ))
            }
        </Swiper>
        </>
    );
}

export default RoundCarousal;