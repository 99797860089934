import bg from "../assets/images/home-bg.png";
import { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../AuthContext';
import randn from "randn"; // Import the useAuth hook

export const Login = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const navigate = useNavigate();
    const { login } = useAuth(); // Use the login function from the context
    const { t } = useTranslation();
    const [forgotPass, setForgotPass] = useState(false);
    const [codeSent, setCodeSent] = useState(false);
    const [code, setCode] = useState('');
    const [codeField, setCodeField] = useState(false);
    const [passChange, setPassChange] = useState(false);
    const [passField, setPassField] = useState('');
    const [confirmField, setConfirmField] = useState('');

    const handleLogin = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/login`, formData);
            if (response.status === 200) {
                console.log(response.data);
                login(response.data.user); // Update the global state
                localStorage.setItem('token', response.headers['authorization']);
                navigate('/profile');
            } else {
                toast.error(t('invalid_credentials'));
            }
        } catch (error) {
            toast.error(t('login_failed'));
        }
    };

    const sendCode = async () => {
        try{
        const otp = randn(5)
        setCode(otp)
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/sendOtp`, {
            email: formData.email,
            otpCode: otp,
            forgetPass: true,
        });
        if(res.status === 200){
            if(res.data.message === 'User does not exist'){
                toast.error(t('user_does_not_exist'));
                return
            }
            setCodeSent(true);
            toast.success(t('otp_sent'));
        }
        else{
            toast.error(t('otp_not_sent'));
        }
        }catch(e){
            console.log(e)
            toast.error(t('otp_not_sent'));
        }
    }

    return (
        <div className="mt-20 font-custom2">
            <div className="flex flex-col items-center justify-center px-5 md:px-20 md:min-h-screen md:max-h-screen">
                <img src={bg} alt="bg"
                     className="hidden min-w-[100vw] min-h-[30vh] md:min-h-[110vh] md:block absolute top-0 left-0 -z-10 "/>
                {
                    passChange ? <div className='
                    mt-10 sm:mt-20 md:mt-0 max-w-[95vw]
                    bg-white
                    p-10 rounded-lg shadow-lg
                    flex flex-col items-center justify-center gap-7
                    mb-10
                '>
                        <h1 className="self-start text-2xl font-bold">{t('change_pass')}</h1>
                        <div className="flex flex-col gap-5">
                            <div className="flex flex-col">
                                <label className="text-sm">{t('password')}</label>
                                <input value={passField} className="p-2 rounded-lg bg-gray-100 min-w-[300px]" type="password" placeholder={t('password')}
                                       onChange={(e) => setPassField(e.target.value)}/>
                            </div>
                            <div className="flex flex-col">
                                <label className="text-sm">{t('confirm_password')}</label>
                                <input value={confirmField} className="p-2 rounded-lg bg-gray-100" type="password"
                                       placeholder={t('confirm_password')}
                                       onChange={(e) => setConfirmField(e.target.value)}/>
                            </div>
                        </div>
                        <div className="min-w-full text-center">
                            <button onClick={async () => {
                                if (passField !== confirmField) {
                                    toast.error(t('passwords_do_not_match'));
                                    return;
                                }
                                const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/changePass`, {
                                    email: formData.email,
                                    password: passField,
                                });
                                if (res.status === 200) {
                                    toast.success(t('password_changed'));
                                    setPassChange(false);
                                } else {
                                    toast.error(t('password_not_changed'));
                                }
                            }} className="min-w-full bg-blue-400 p-2 rounded-lg text-white">
                                {t('change_pass')}
                            </button>
                        </div>
                        </div> :
                        <div className='
                    mt-10 sm:mt-20 md:mt-0 max-w-[95vw]
                    bg-white
                    p-10 rounded-lg shadow-lg
                    flex flex-col items-center justify-center gap-7
                    mb-10
                '>
                            <h1 className="self-start text-2xl font-bold">{t('login')}</h1>
                            <div className="flex flex-col gap-5">
                                <div className="flex flex-col w-72">
                                    <label className="text-sm">{t('email')}</label>
                                    <input className="p-2 rounded-lg bg-gray-100" type="text" placeholder={t('email')}
                                           onChange={(e) => setFormData({...formData, email: e.target.value})}/>
                                </div>
                                {!forgotPass ? <div className="flex flex-col">
                                    <label className="text-sm">{t('password')}</label>
                                    <input className="p-2 rounded-lg bg-gray-100" type="password"
                                           placeholder={t('password')}
                                           onChange={(e) => setFormData({...formData, password: e.target.value})}/>
                                    <p onClick={() => {
                                        setForgotPass(true);
                                    }} className="cursor-pointer text-xs mt-2" href="/signup">{t('forgot_pass')}</p>
                                </div> : codeSent && <div className="flex flex-col">
                                    <label className="text-sm">{t('code')}</label>
                                    <input className="p-2 rounded-lg bg-gray-100" type="text" placeholder={t('code')}
                                           onChange={(e) => setCodeField(e.target.value)}/>
                                </div>}
                            </div>
                            <div className="min-w-full text-center">
                                {!forgotPass ? <button className="min-w-full bg-blue-400 p-2 rounded-lg text-white"
                                                       onClick={handleLogin}>{t('login')}</button> :
                                    !codeSent ? <button className="min-w-full bg-blue-400 p-2 rounded-lg text-white"
                                                        onClick={sendCode}>
                                        {t('send_code')}
                                    </button> : <button onClick={() => {
                                        if (codeField === code) {
                                            setForgotPass(false);
                                            setCodeSent(false);
                                            setCode('');
                                            setCodeField('');
                                            setPassChange(true)
                                        } else {
                                            toast.error(t('wrong_code'));
                                        }
                                    }} className="min-w-full bg-blue-400 p-2 rounded-lg text-white">
                                        {t('verify_code')}
                                    </button>}
                                <a className="text-xs mt-2" href="/signup">{t('no_account')}</a>
                            </div>
                        </div>
                }
            </div>
        </div>
    );
};
