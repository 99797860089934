import React from "react";
import bg1 from "../assets/images/individual.JPG";
import bg2 from "../assets/images/shkollaenotit.JPG";
import bg3 from "../assets/images/grup.JPG";
import blueBlur from "../assets/images/bg-blue-blur.png";
import blueBg from "../assets/images/blue-bg.png";
import blueLines from "../assets/images/blue-lines.png";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./styles.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AboutImg from "../assets/images/home.about.1.png";
import AboutImg2 from "../assets/images/about.home.2.png";
import AboutImg3 from "../assets/images/about.home.3.png";
import AboutImg4 from "../assets/images/about.home.4.png";
import AboutImg5 from "../assets/images/about.home.5.png";
import AboutImg6 from "../assets/images/about.home7.png";
import AboutImg7 from "../assets/images/about.hom.9.png";
import Video1 from "../assets/video/stepwebsite1.mp4";
import Video2 from "../assets/video/stepwebsite2.mp4";
import Video3 from "../assets/video/stepwebsite3.mp4";
import RoundCarousal from "../components/roundCarousel";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-cards";

import "./swiper.css";

// import required modules
import { EffectCards, Autoplay, Pagination } from "swiper/modules";

export const Home = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const slides = [
    {
      head: t("header1"),
      body: t("des1"),
      video: Video1,
    },
    {
      head: t("header2"),
      body: t("des2"),
      video: Video2,
    },
    {
      head: t("header3"),
      body: t("des3"),
      video: Video3,
    },
  ];

  const comments = [
    {
      name: t("comments_section.comment1.name"),
      comment: t("comments_section.comment1.comment"),
      rating: 5.0,
    },
    {
      name: t("comments_section.comment2.name"),
      comment: t("comments_section.comment2.comment"),
      rating: 5.0,
    },
    {
      name: t("comments_section.comment3.name"),
      comment: t("comments_section.comment3.comment"),
      rating: 4.8,
    },
  ];

  const cardInfo = [
    {
      title: t("team_competition"),
      text: t("team_competition_sub"),
      img: bg1,
    },
    {
      title: t("kids_school"),
      text: t("kids_school_sub"),
      img: bg2,
    },
    {
      title: t("private_training"),
      text: t("private_training_sub"),
      img: bg3,
    },
  ];

  return (
    <div className="overflow-x-hidden overflow-y-hidden font-custom2">
      <div className="2xl:min-h-[82vh] flex flex-col md:flex-row justify-center items-end">
        <div className="min-w-full min-h-full py-5 md:p-0 w-[100vw]">
          <Carousel
            showThumbs={false}
            showStatus={false}
            infiniteLoop={true}
            stopOnHover={false}
            emulateTouch={true}
            autoPlay={true}
            interval={4000}
          >
            {slides.map((slide, index) => (
              <div
                key={index}
                className="carousel-slide relative mt-5 flex bg-transparent flex-col items-center justify-center w-full h-[35vh] sm:h-[40vh] md:h-[45vh] lg:h-[60vh] xl:h-[100vh] 2xl:h-[100vh]"
              >
                <video
                  autoPlay
                  playsInline
                  loop
                  muted
                  className="absolute top-0 left-0 w-full h-full -z-10 mt-10 object-cover"
                >
                  <source src={slide.video} type="video/mp4" />
                </video>
                <div className="flex flex-col items-center justify-center w-full h-full gap-1">
  <div className="w-2/3 sm:w-1/2">
    <h1 className="carousel-slide h1 mt-28 sm:mt-20 text-left text-white font-bold">
      {slide.head}
    </h1>
    <p className="carousel-slide p sm:mt-4 text-left text-white">
      {slide.body}
    </p>
  </div>
  <div className="flex items-end justify-end w-2/3 mt-2 mb-16 sm:w-1/2 sm:mt-5">
    <button
      onClick={() => navigate("/registration")}
      className="button self-end p-2 px-5 text-xs sm:text-base sm:px-10 rounded-xl"
    >
      <span className="button-text">{t("register")}</span>
    </button>
  </div>
</div>




              </div>
            ))}
          </Carousel>
        </div>
      </div>

      {/* cards */}
      <div className="relative w-full py-[3rem]" style={{ marginTop: "2rem" }}>
        <img
          src={blueBlur}
          alt="blue Blur"
          className="min-w-[100vw] min-h-[40vh] md:min-h-[40vh] h-[40vh] absolute -z-10 -top-0 sm:-top-0"
        />
        <img
          src={blueLines}
          alt="blue lines"
          className="min-w-[100vw] min-h-[40vh] md:min-h-[40vh] max-h-[40vh] absolute -z-10 -top-0 sm:top-0"
        />
        <div className="max-w-[1240px] mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 relative z-10">
          {cardInfo.map((card, index) => (
            <div
              key={index}
              className="w-full shadow-xl flex flex-col p-4 my-4 rounded-lg bg-transparent border-2 border-dotted border-blue-300 hover:scale-15 duration-300"
            >
              <div className="relative group">
                <img
                  className="w-30 mx-auto mt-[-3rem] bg-white rounded-lg transform transition-transform duration-300 group-hover:scale-15"
                  src={card.img}
                  alt="/"
                />
              </div>
              <h2 className="mb-10 text-3xl font-bold text-gray-700 text-center mt-8">
                {card.title}
              </h2>
              <div className="text-center font-medium">
                <p className="py-2 mx-8 mt-1 text-sm sm:text-base lg:text-lg">{card.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-20 p-5 items-center justify-center relative pt-[5rem] pb-[5rem]">
        <img
          src={blueBlur}
          alt="blue Blur"
          className="min-w-[100vw] min-h-[40vh] md:min-h-[40vh] h-[40vh] absolute -z-10 -top-0 sm:-top-0"
        />
        <img
          src={blueLines}
          alt="blue lines"
          className="min-w-[100vw] min-h-[40vh] md:min-h-[40vh] max-h-[40vh] absolute -z-10 -top-0 sm:top-0"
        />
        <div className="flex flex-col gap-5 text-center md:w-1/3 relative">
          <div className="relative p-5 rounded-lg bg-transparent">
            <h1 className="mb-10 text-4xl font-bold text-gray-700">{t("around")}</h1>
            <p className="text-sm sm:text-base lg:text-lg">
              {t("home_aboutus")}
            </p>
          </div>
        </div>
        <div className="px-10 w-full sm:w-2/3 md:w-1/3">
          <Swiper
            effect={"cards"}
            grabCursor={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            modules={[EffectCards, Autoplay]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img src={AboutImg} className="w-[95%] h-full" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={AboutImg2} className="w-[95%] h-full" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={AboutImg3} className="w-[95%] h-full" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={AboutImg4} className="w-[95%] h-full" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={AboutImg5} className="w-[95%] h-full" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={AboutImg6} className="w-[95%] h-full" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={AboutImg7} className="w-[95%] h-full" />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>

      <div className="hidden w-full gradient md:absolute -z-20"></div>

      <div className="relative h-full flex-col pt-[5rem] pb-[5rem] flex items-center justify-center w-full gap-5 mt-5 text-center align-middle md:mt-5">
        <h1 className="mb-20 text-4xl font-bold text-gray-700 animation-text">
          {t("trainers")}
        </h1>
        <img
          src={blueBg}
          alt="blue bg"
          className="min-w-[100vw] min-h-[40vh] max-h-[40vh] absolute -z-10 top-5"
        />
        <img
          src={blueLines}
          alt="blue bg"
          className="min-w-[100vw] min-h-[40vh] max-h-[40vh] absolute -z-10 top-5"
        />
        <div className="flex justify-center items-center align-middle max-w-[80vw]">
          <RoundCarousal />
        </div>
        <p className="text-sm sm:text-base lg:text-lg ">
          {t("trainers_desc")}
        </p>
        <button
          onClick={() => navigate("/staff")}
          className="button small-button flex items-center justify-center gap-2 px-2 py-2 text-xl transition-all duration-300 bg-white text-darkBlue rounded-[12px] shadow-lg hover:bg-blue-500 hover:text-white hover:scale-[105%] hover:shadow-none"
        >
          {t("look")}
        </button>
      </div>

      {/* Google Maps and Address with Comments */}
      <div className="flex flex-col md:flex-row w-full h-[500px]">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2934.720398934137!2d21.1624077!3d42.6198779!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13549c2283a7f68f%3A0x6e955de44b08c0f8!2sSTEP%20Sport%20Center!5e0!3m2!1sen!2s!4v1687257864287!5m2!1sen!2s"
          width="100%"
          height="100%"
          className="md:w-2/3"
          style={{ border: "0" }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        <div className="flex flex-col items-center justify-center w-full p-5 bg-gray-100 md:w-1/3">
          <h2 className="text-2xl font-bold">{t("Step Sport Center")}</h2>
          <p className="mt-4 text-lg">
            {t("Address: Rr. XYZ, Prishtina, Kosovo")} <br />
            {t("Phone: +383 44 123 456")} <br />
            {t("Email: info@stepsportcenter.com")}
          </p>
          <Swiper
            modules={[Pagination, Autoplay]}
            pagination={{ clickable: true }}
            autoplay={{ delay: 3000 }}
            className="mySwiper mt-4"
          >
            {comments.map((comment, index) => (
              <SwiperSlide key={index}>
                <div className="flex flex-col items-center">
                  <div className="flex items-center mt-2 justify-center">
                    <span className="text-yellow-500 text-xl ml-10">
                      {"★".repeat(Math.floor(comment.rating))}
                      {"☆".repeat(5 - Math.floor(comment.rating))}
                    </span>
                    <span className="text-lg">({comment.rating})</span>
                  </div>
                  <p className="mt-2 text-center text-base text-gray-600">
                    "{comment.comment}"
                  </p>
                  <p className="mt-1 text-sm text-gray-500">- {comment.name}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};
