import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export const IndividualDiscountCard = ({ formData, setFormData, discountData }) => {
    const { t } = useTranslation();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const [dropDown, setDropDown] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState(discountData?.months[0]);

    // const discountedCost = Math.ceil(selectedMonth?.cost * (1 - (discountData.discount / 100)));

    const [plan, setPlan] = useState(false);

    const handleChosePlan = () => {
        setFormData((prevData) => ({
            ...prevData,
            time: selectedMonth.time,
            // discount: discountData.discount,
            totalAmount: selectedMonth.cost,
            // payableAmount: discountedCost,
            payableAmount: selectedMonth.cost,
            entries: discountData.entries,
            premium: discountData.entries === "unlimited" ? true : false,
        }));
    };

    const isSelected = () => {
        return (
            formData.time === selectedMonth.time &&
            // formData.discount === discountData.discount &&
            formData.totalAmount === selectedMonth.cost &&
            // formData.payableAmount === discountedCost &&
            formData.entries === discountData.entries
        );
    };

    return (
        <div className="flex flex-col gap-5 border-[#003DFE] border-2 rounded-lg min-w-[300px] p-5 max-h-[300px] h-fit font-custom2">
            <div className="flex flex-col gap-2 rounded-2xl relative w-[200px]">
                <button
                    onClick={() => setDropDown(!dropDown)}
                    id="dropdownDefaultButton"
                    data-dropdown-toggle="dropdown"
                    className="text-blue-700 bg-[#D5DFFF] bg-opacity-60 font-medium rounded-lg text-sm px-5 py-1.5 text-center inline-flex items-center"
                    type="button"
                >
                    {selectedMonth?.time} {t('months')}
                    <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                    </svg>
                </button>

                <div id="dropdown" className={`z-10 bg-white text-black -right-1 top-10 absolute divide-y divide-gray-100 rounded-lg shadow w-44 ${dropDown ? 'block' : 'hidden'}`}>
                    <ul className="py-2 text-sm" aria-labelledby="dropdownDefaultButton">
                        {discountData?.months.map((month, index) => (
                            <li key={index}>
                                <a
                                    onClick={() => { setSelectedMonth(month); setDropDown(false); }}
                                    href="#"
                                    className="block px-4 py-2 hover:bg-gray-200"
                                >
                                    {month.time} {t('months')}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="flex flex-col gap-3">
                <div className="flex flex-row items-center gap-5">
                    <div className="relative">
                        <span>{selectedMonth?.cost} €</span>
                    </div>
                    {/* <span className="p-1 px-3 rounded-2xl bg-[#D5DFFF] bg-opacity-60 text-blue-700">{discountData.discount}% {t('off')}</span>
                    <span>{discountedCost}</span> */}
                </div>
                <span>{t('entries')}: {discountData.entries === "unlimited" 
                    ? <span className="p-1 px-3 rounded-2xl bg-[#D5DFFF] bg-opacity-60 text-blue-700">{t('unlimited')}</span> 
                    : <span className="p-1 px-3 rounded-2xl bg-[#D5DFFF] bg-opacity-60 text-blue-700">{discountData?.entries * selectedMonth?.time}</span>}</span>
                <button 
                    onClick={() => { setPlan(!plan); handleChosePlan() }}
                    className={`min-w-full border-[#003DFE] font-bold border-2 rounded-lg p-1 hover:bg-[#003DFE] hover:text-white transition-all duration-300 ${isSelected() ? 'bg-[#003DFE] text-white' : 'text-[#003DFE]'} `}
                >
                    {isSelected() ? t('unChoosePlan') : t('choosePlan')}
                </button>
            </div>
        </div>
    );
};
