import { useTranslation } from "react-i18next";

export const Success = ({ id }) => {
    const { t } = useTranslation();

    return (
        <div className="mt-20 flex flex-col items-center font-custom2">
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                <strong className="font-bold">{t("success")}!</strong>
                <span className="block sm:inline">{t("successmessage")}</span>
            </div>
            <div className="mt-4">
                {/* <h3>{t("successid")}: {id}</h3> */}
            </div>
        </div>
    );
};