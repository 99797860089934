
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export const FitnessKidDiscountCard = ({ formData, setFormData, discountData }) => {

    const [days, setDays] = useState([]);
    const [dropDown, setDropDown] = useState(false);
    const [dropDown2, setDropDown2] = useState(false);
    const [appointments, setAppointments] = useState("2");
    const [entries, setEntries] = useState(8);
    const [payment, setPayment] = useState(30);
    const [time, setTime] = useState("18:00");
    const [saturdayTime, setSaturdayTime] = useState("Select Time Slot");
    const [dropDownSaturday, setDropDownSaturday] = useState(false);
    const [plan, setPlan] = useState(false);
    const [timeSlots, setTimeSlots] = useState([]);
    const { t } = useTranslation();


    const handleDayClick = (day) => {
        const isSaturday = day === 'SATURDAY'; // Assuming day is a string like 'SATURDAY'
        const slotTime = isSaturday ? '08:00' : time;
    
        if (timeSlots.some(slot => slot.day === day)) {
            setTimeSlots(timeSlots.filter(slot => slot.day !== day));
        } else if (timeSlots.length < appointments) {
            setTimeSlots([...timeSlots, { day, time: slotTime }]);
        }
    };

    const handleSaturdayTimeClick = (time) => {
        const day = "SATURDAY";
        console.log("INSIDE THE SATURDAY");
        console.log("THESE ARE THE SLOTS IN TIMESLOTS: ", timeSlots);
    
        // Check if a timeslot for Saturday already exists
        const saturdaySlotIndex = timeSlots.findIndex(slot => slot.day.trim() === day);
    
        console.log("THIS IS THE SATURDAY SLOT INDEX: ", saturdaySlotIndex);
    
        if (saturdaySlotIndex !== -1) {
            // Update the existing Saturday timeslot with the new time
            const updatedTimeSlots = [...timeSlots];
            updatedTimeSlots[saturdaySlotIndex].time = time;
            setTimeSlots(updatedTimeSlots);
            console.log("UPDATED TIME SLOTS", updatedTimeSlots);
            console.log("ACTUAL TIME SLOTS:", timeSlots);
        } else if (timeSlots.length < appointments) {
            // Add a new timeslot for Saturday if it does not exist and the limit is not reached
            setTimeSlots([...timeSlots, { day, time }]);
        }
    };
    

    const handleChosePlan = () => {
        if (timeSlots.length < appointments) {
            toast.warning(`Please select ${appointments} days`);
        } else {
            setFormData((prevData) => ({ ...prevData, days: timeSlots }));
            setFormData((prevData) => ({ ...prevData, time: appointments }));
            setFormData((prevData) => ({ ...prevData, timings: time }));
            setFormData((prevData) => ({ ...prevData, entries: entries }));
            setFormData((prevData) => ({ ...prevData, totalAmount: payment }));
            setFormData((prevData) => ({ ...prevData, payableAmount: payment }));
        }
    };

    return (
        <div className="flex flex-col gap-5 border-[#003DFE] border-2 rounded-lg min-w-[300px] p-5 max-h-[500px] h-fit font-custom2">
            <div className="flex flex-col gap-2 rounded-2xl relative w-[200px]">
                <button
                    onClick={() => setDropDown(!dropDown)}
                    id="dropdownDefaultButton"
                    data-dropdown-toggle="dropdown"
                    className="text-blue-700 bg-[#D5DFFF] bg-opacity-60 font-medium rounded-lg text-sm px-5 py-1.5 text-center inline-flex items-center"
                    type="button"
                >
                    {appointments}x {t('Week')}
                    <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                    </svg>
                </button>

                <div id="dropdown" className={`z-10 bg-white text-black -right-1 top-10 absolute divide-y divide-gray-100 rounded-lg shadow w-44 ${dropDown ? 'block' : 'hidden'}`}>
                    <ul className="py-2 text-sm" aria-labelledby="dropdownDefaultButton">
                        <li>
                            <a
                                onClick={(e) => { e.preventDefault(); setAppointments("1"); setDropDown(false); setEntries(4); setPayment(20); setDays([]); setTimeSlots([]) }}
                                href="#"
                                className="block px-4 py-2 hover:bg-gray-200"
                            >
                                1x {t('Week')}
                            </a>
                        </li>
                        <li>
                            <a
                                onClick={(e) => { e.preventDefault(); setAppointments("2"); setDropDown(false); setEntries(8); setPayment(30); setDays([]); setTimeSlots([]) }}
                                href="#"
                                className="block px-4 py-2 hover:bg-gray-200"
                            >
                                2x {t('Week')}
                            </a>
                        </li>
                        <li>
                            <a
                                onClick={(e) => { e.preventDefault(); setAppointments("3"); setDropDown(false); setEntries(12); setPayment(40); setDays([]); setTimeSlots([]) }}
                                href="#"
                                className="block px-4 py-2 hover:bg-gray-200"
                            >
                                3x {t('Week')}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="flex flex-row gap-2">
                <span className="p-1 px-3 text-blue-700">{t('time')}: </span>
                <div className="flex flex-col gap-2 rounded-2xl relative w-[200px]">
                    <button
                        onClick={() => setDropDown2(!dropDown2)}
                        id="dropdownDefaultButton"
                        data-dropdown-toggle="dropdown"
                        className="text-blue-700 bg-[#D5DFFF] bg-opacity-60 font-medium rounded-lg text-sm px-5 py-1.5 text-center inline-flex items-center"
                        type="button"
                    >
                        {time}
                        <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                        </svg>
                    </button>

                    <div id="dropdown" className={`z-10 bg-white text-black -right-1 top-10 absolute divide-y divide-gray-100 rounded-lg shadow w-44 ${dropDown2 ? 'block' : 'hidden'}`}>
                        <ul className="py-2 text-sm" aria-labelledby="dropdownDefaultButton">
                            <li>
                                <a
                                    onClick={(e) => { e.preventDefault(); setTime("18:00"); setDropDown2(false); }}
                                    href="#"
                                    className="block px-4 py-2 hover:bg-gray-200"
                                >
                                    18:00 - 19:00
                                </a>
                            </li>
                            <li>
                                <a
                                    onClick={(e) => { e.preventDefault(); setTime("19:00"); setDropDown2(false); }}
                                    href="#"
                                    className="block px-4 py-2 hover:bg-gray-200"
                                >
                                    19:00 - 20:00
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-1">
                <div className="p-1 px-3 text-blue-700">
                    {t('schedule')}
                    <div className="p-2 px-3 ml-5 w-full rounded-2xl bg-[#D5DFFF] bg-opacity-60 text-blue-700">
                        <div className="grid grid-cols-3 gap-2">
                            {[t('monday'), t('tuesday'), t('wednesday')].map(day => (
                                <div
                                    key={day}
                                    className={`border-[1px] rounded border-blue-700 px-2 cursor-pointer text-center ${timeSlots.some(slot => slot.day === day) ? 'bg-blue-700 text-white rounded bg-opacity-75' : ''}`}
                                    onClick={() => handleDayClick(day)}
                                >
                                    {day}
                                </div>
                            ))}
                        </div>
                        <div className="grid grid-cols-3 gap-2 mt-2">
                            {[t('thursday'), t('friday'), t('saturday')].map(day => (
                                <div
                                    key={day}
                                    className={`border-[1px] rounded border-blue-700 px-2 cursor-pointer text-center ${timeSlots.some(slot => slot.day === day) ? 'bg-blue-700 text-white rounded bg-opacity-75' : ''}`}
                                    onClick={() => handleDayClick(day)}
                                >
                                    {day}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {timeSlots.some(slot => slot.day === t('saturday')) && (
                    <div className="flex flex-col gap-2 rounded-2xl relative w-[200px] mt-2">
                        <button
                            onClick={() => setDropDownSaturday(!dropDownSaturday)}
                            id="dropdownSaturdayButton"
                            data-dropdown-toggle="dropdown"
                            className="text-blue-700 bg-[#D5DFFF] bg-opacity-60 font-medium rounded-lg text-sm px-5 py-1.5 text-center inline-flex items-center"
                            type="button"
                        >
                            {saturdayTime}
                            <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                            </svg>
                        </button>
                        <div id="dropdown" className={`z-10 bg-white text-black -right-1 top-10 absolute divide-y divide-gray-100 rounded-lg shadow w-44 ${dropDownSaturday ? 'block' : 'hidden'}`}>
                        <ul className="py-2 text-sm" aria-labelledby="dropdownSaturdayButton">
                                <li>
                                    <a
                                        onClick={(e) => { e.preventDefault(); handleSaturdayTimeClick("09:00"); setDropDownSaturday(false); setSaturdayTime("09:00") }}
                                        href="#"
                                        className="block px-4 py-2 hover:bg-gray-200"
                                    >
                                        09:00 - 10:00
                                    </a>
                                </li>
                                <li>
                                    <a
                                        onClick={(e) => { e.preventDefault(); handleSaturdayTimeClick("10:00"); setDropDownSaturday(false); setSaturdayTime("09:00") }}
                                        href="#"
                                        className="block px-4 py-2 hover:bg-gray-200"
                                    >
                                        10:00 - 11:00
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                )}
                <span className="pl-10 mb-5">{t('choose')} {appointments} {t('days_of_week')}</span>
                <div className="pl-10 mb-5">
                    {appointments === "2" ? "30 €" : appointments === "3" ? "40 €" : "20 €"}
                </div>
                <button onClick={() => { setPlan(!plan); handleChosePlan() }} className={`min-w-full border-[#003DFE] font-bold border-2 rounded-lg p-1 hover:bg-[#003DFE] hover:text-white transition-all duration-300 ${plan ? 'bg-[#003DFE] text-white' : 'text-[#003DFE]'}`}>
                    {plan ? t('unChoosePlan') : t('choosePlan')}
                </button>
            </div>
        </div>
    );
};

