import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home } from './pages/home';
import { Navbar } from './components/navbar';
import './assets/i18n';
import { Footer } from './components/footer';
import PaymentForm from './components/PaymentForm';
import { Info } from './pages/info';
import { Staff } from './pages/staff';
import { Registration } from './pages/registeration';
import { RegistrationAgents } from './pages/registerationAgents';
import {Login} from "./pages/login";
import {Signup} from "./pages/signup";
import {Account} from "./pages/account";
import {AuthProvider} from "./AuthContext";
import {Success} from "./pages/success";
import {Denied} from "./pages/denied";

function App() {
  return (
    <Router>
      <AuthProvider>
      <div className="overflow-x-hidden">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/info" element={<Info />} />
          <Route path="/staff" element={<Staff />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/agentregistration" element={<RegistrationAgents />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/profile" element={<Account/>} />
            <Route path="/success" element={<Success/>} />
            <Route path="/denied"  element={<Denied/>} />
            <Route path="/paymentform"  element={<PaymentForm/>} />
        </Routes>
        <Footer />
      </div>
      </AuthProvider>
    </Router>
  );
}

export default App;
