import blueBg from "../assets/images/blue-bg.png";
import logoBig from "../assets/images/Logo-step.png"; 
import { useTranslation } from "react-i18next";
import { useState } from "react";
import axios from 'axios';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaLink } from 'react-icons/fa';

export const Footer = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState(false);

    const handleSendMessage = async () => {
        try {
            console.log("Sending message...");
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/message/send`, { phone, email, message });
            if (response.status === 200) {
                toast.success('Message sent successfully!');
                setPhone("");
                setEmail("");
                setMessage("");
            }
        } catch (error) {
            console.error('Error sending message:', error.message);
            toast.error('Failed to send message.');
        }
    };

    return (
        <footer className="relative bg-gray-900 text-white py-10 font-custom2">
            <div className="absolute inset-0 w-full h-full overflow-hidden -z-10">
                <svg viewBox="0 0 1440 320" className="absolute bottom-0 w-full h-full">
                    <path fill="#1E3A8A" fillOpacity="1" d="M0,96L80,117.3C160,139,320,181,480,197.3C640,213,800,203,960,192C1120,181,1280,171,1360,165.3L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
                </svg>
            </div>
            <div className="relative z-10 flex flex-col sm:flex-row justify-between items-center container mx-auto px-5">
                <div className="flex flex-col items-center">
                    <img src={logoBig} alt="logo" className="h-20" />
                    <div className="flex justify-center gap-5 mb-4">
                        <a href="https://www.facebook.com/profile.php?id=100077376647582&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
                            <FaFacebookF className="hover:text-blue-500 cursor-pointer" />
                        </a>
                        <a href="https://www.instagram.com/stepsportcenter?igsh=eGk4d2Z0NmYyZTdo" target="_blank" rel="noopener noreferrer">
                            <FaInstagram className="hover:text-pink-500 cursor-pointer" />
                        </a>
                        <a href="https://linktr.ee/StepSportCenter?fbclid=PAZXh0bgNhZW0CMTEAAab-jMdOYXcTDUid0g5hQfCGeMuItxrQydbTRx0Og3ybI17Y3OGw3bkMCnk_aem_TZIZ2U6MaIXwcKeEsavbyg" target="_blank" rel="noopener noreferrer">
                            <FaLink className="hover:text-blue-500 cursor-pointer" />
                        </a>
                    </div>
                </div>
                <div className="flex flex-col items-center sm:items-start">
                    <p className="cursor-pointer" onClick={() => navigate('/')}>{t('home')}</p>
                    <p className="cursor-pointer" onClick={() => navigate('/info')}>{t('around')}</p>
                    <p className="cursor-pointer" onClick={() => navigate('/staff')}>{t('staff')}</p>
                    <p className="cursor-pointer" onClick={() => navigate('/registration')}>{t('register')}</p>
                </div>
                <div className="flex flex-col gap-5 sm:w-1/3 mt-5 sm:mt-0">
                    <p className="text-center sm:text-left">{t("send")}</p>
                    <div className="flex flex-col gap-2">
                        <input type="text" required className='p-2 rounded-lg bg-gray-800 border border-gray-700 placeholder-gray-500 text-white' value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="+383 44 000 000" />
                        <input type="email" required className='p-2 rounded-lg bg-gray-800 border border-gray-700 placeholder-gray-500 text-white' value={email} onChange={(e) => setEmail(e.target.value)} placeholder="sample@gmail.com" />
                    </div>
                    <textarea className='h-20 p-2 rounded-lg bg-gray-800 border border-gray-700 placeholder-gray-500 text-white' value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Message" />
                    <label className={`text-red-500 mt-2 ${error ? 'block' : 'hidden'}`}>{t('fill_all_fields')}</label>
                    <button
                        onClick={() => {
                            if (!phone || !email || !message) {
                                toast.error(t('fill_all_fields'));
                                setError(true);
                            } else {
                                handleSendMessage();
                                setError(false);
                            }
                        }}
                        className="mt-4 p-2 px-5 bg-blue-500 hover:bg-blue-700 transition-colors duration-300 rounded-xl max-w-fit self-end"
                    >
                        {t('send')}
                    </button>
                </div>
            </div>
        </footer>
    );
};
