import React from "react";
import infoMain from "../assets/images/main.about.JPG";
import blueBg from "../assets/images/blue-bg.png";
import blueLines from "../assets/images/blue-lines.png";
import comments1 from "../assets/images/comments-1.png";
import olympicSwimming from "../assets/images/olympic-swimming.jpg";
import olympicSwimming1 from "../assets/images/olympic-swimming1.jpg";
import trainingPool from "../assets/images/training-pool.jpg";
import sauna from "../assets/images/sauna.jpg";
import fitnes from "../assets/images/fitnes.jpg";
import pool from "../assets/images/pool.jpg";
import aboutus from "../assets/images/aboutusPic.jpg";
// import poolWater from "../assets/video/pool_water.mp4";
import { useTranslation } from "react-i18next";
import "./styles.css";

export const Info = () => {
  const { t } = useTranslation();

  const factSwimming = [
    { fact: t("factnumber3") },
    { fact: t("factnumber2") },
    { fact: t("factnumber1") },
  ];

  const factTraining = [
    { fact: t("fact4number2") },
    { fact: t("fact4number3") },
    { fact: t("fact4number1") },
  ];

  const factTraining2 = [
    { fact: t("fact4number2") },
    { fact: t("fact4number3") },
  ];

  const factRecreational = [
    { fact: t("factrnumber2") },
    { fact: t("factrnumber1") },
  ];

  const factSauna = [
    // Add sauna facts here if needed
  ];

  const CheckmarkIcon = () => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mr-2"
    >
      <path
        d="M6.00016 10.172L3.82816 7.99997L3.11416 8.70797L6.00016 11.586L13.0002 4.58597L12.2932 3.87897L6.00016 10.172Z"
        fill="#1E3A8A"
      />
       <path
        d="M6.00016 10.172L3.82816 7.99997L3.11416 8.70797L6.00016 11.586L13.0002 4.58597L12.2932 3.87897L6.00016 10.172Z"
        fill="#1E3A8A"
        stroke="#1E3A8A"
        strokeWidth="1.8"
      />
    </svg>
  );

  const FactList = ({ facts }) => (
    <div className="grid items-end justify-end grid-cols-1 mt-4 sm:grid-cols-2">
      {facts.map((fact, index) => (
        <div
          key={index}
          className="flex flex-col items-center gap-2 p-5 sm:flex-row"
        >
          <CheckmarkIcon />
          <h1 className="text-xl text-gray-700">{fact.fact}</h1>
        </div>
      ))}
    </div>
  );

  return (
    <div className="font-custom2">
      <div>
        <img
          src={infoMain}
          alt="info bg"
          className="h-auto mt-16 min-w-[100vw] left-0 -z-20"
        />
      </div>
{/* <div className="min-h-[30vh] flex flex-col items-center text-center md:px-[20vw] relative mt-20">
        <img
          src={blueBg}
          alt="blue bg"
          className="min-w-[100vw] min-h-[40vh] max-h-[60vh] h-[60vh] absolute -mt-36 -z-0 -top-5"
        />
        <img
          src={blueLines}
          alt="blue bg"
          className="min-w-[100vw] min-h-[40vh] max-h-[40vh] absolute -mt-36 -z-0 top-10"
        />
        <h1 className="px-5 pb-10 text-3xl font-bold text-gray-700 sm:px-0 rotate-text mb-10 text-4xl font-bold text-gray-700">
          {t("around")}
        </h1>
        <p className="px-5 text-xl text-gray-700 sm:px-0 text-sm sm:text-base lg:text-lg">
          {t("around_sub")}
        </p>
      </div>*/}

      <div className="relative container max-w-[100vw] sm:max-w-[90vw] flex flex-col gap-10 px-5 sm:pr-0 sm:pl-20 mx-auto mt-0 sm:mt-10 text-start rounded-3xl">
        <div className="overlay"></div>

        <div className="flex flex-col gap-10 text-justify sm:flex-row">
          <div>
            <h1 className="p-10 text-2xl font-bold text-black animated-heading mb-10 text-4xl font-bold text-gray-700">
              {t("history")}
            </h1>
            <p className="text-black animated-text text-sm sm:text-base lg:text-lg">
              {t("around_sub")}
            </p>
          </div>
          <img src={comments1} alt="comments" />
        </div>
      </div>

      <div className="container flex flex-col items-center justify-center mx-auto mt-0 text-center sm:mt-10">
        <img
          src={blueBg}
          alt="blue bg"
          className="min-w-[100vw] min-h-[40vh] max-h-[40vh] absolute left-0 mt-[300px] -z-10"
        />
        <img
          src={blueLines}
          alt="blue bg"
          className="min-w-[100vw] min-h-[40vh] max-h-[40vh] absolute left-0 mt-[300px] -z-10"
        />
        <h1 className="text-5xl text-gray-700 font-bold p-10 mb-10 text-4xl font-bold text-gray-700">
          {t("pools_title")}
        </h1>
        <h2 className="mb-10 text-2xl font-bold text-gray-700">
          {t("olympicPool")}
        </h2>
        <div className="relative w-[90vw] sm:w-[80vw] bg-white rounded-b-lg shadow-lg">
          <img
            src={olympicSwimming}
            alt="olympic swimming pool"
            className="rounded-t-lg"
          />
          <div className="p-5">
            <FactList facts={factSwimming} />
          </div>
        </div>
      </div>

      <div className="relative flex flex-col items-center justify-center mx-auto mt-0 text-center sm:mt-10">
        <img
          src={blueBg}
          alt="blue bg"
          className="min-w-[100vw] min-h-[40vh] max-h-[40vh] absolute left-0 -z-10 -top-10"
        />
        <img
          src={blueLines}
          alt="blue bg"
          className="min-w-[100vw] min-h-[40vh] max-h-[40vh] absolute left-0 -z-10 -top-10"
        />
        <h2 className="p-10 text-2xl font-bold text-gray-700 mb-10">
          {t("trainingPool")}
        </h2>
        <div className="relative w-[90vw] sm:w-[80vw] bg-white rounded-b-lg shadow-lg">
          <img
            src={trainingPool}
            alt="training pool"
            className="rounded-t-lg"
          />
          <div className="p-5">
            <FactList facts={factTraining} />
          </div>
        </div>
        <img
          src={blueBg}
          alt="blue bg"
          className="min-w-[100vw] min-h-[40vh] max-h-[40vh] absolute left-0 -z-10 bottom-0"
        />
        <img
          src={blueLines}
          alt="blue bg"
          className="min-w-[100vw] min-h-[40vh] max-h-[40vh] absolute left-0 -z-10 bottom-0"
        />
      </div>

      <div className="container flex flex-col items-center justify-center mx-auto mt-0 text-center sm:mt-10">
        <img
          src={blueBg}
          alt="blue bg"
          className="min-w-[100vw] min-h-[40vh] max-h-[40vh] absolute left-0 mt-[300px] -z-10"
        />
        <img
          src={blueLines}
          alt="blue bg"
          className="min-w-[100vw] min-h-[40vh] max-h-[40vh] absolute left-0 mt-[300px] -z-10"
        />
        <h2 className="p-10 text-2xl font-bold text-gray-700 mb-10">
          {t("training2Pool")}
        </h2>
        <div className="relative w-[90vw] sm:w-[80vw] bg-white rounded-b-lg shadow-lg mb-5">
          <img
            src={olympicSwimming1}
            alt="olympic swimming pool"
            className="rounded-t-lg"
          />
          <div className="p-5">
            <FactList facts={factRecreational} />
          </div>
        </div>
      </div>

      <div className="container flex flex-col items-center justify-center mx-auto mt-0 text-center sm:mt-10">
        <img
          src={blueBg}
          alt="blue bg"
          className="min-w-[100vw] min-h-[40vh] max-h-[40vh] absolute left-0 mt-[300px] -z-10"
        />
        <img
          src={blueLines}
          alt="blue bg"
          className="min-w-[100vw] min-h-[40vh] max-h-[40vh] absolute left-0 mt-[300px] -z-10"
        />
        <h2 className="p-10 text-2xl font-bold text-gray-700 mb-10">
          {t("pool")}
        </h2>
        <div className="relative w-[90vw] sm:w-[80vw] bg-white rounded-b-lg shadow-lg">
          <img
            src={pool}
            alt="olympic swimming pool"
            className="rounded-t-lg"
          />
          <div className="p-5">
            <FactList facts={factTraining2} />
          </div>
        </div>
      </div>

      <div className="relative flex flex-col items-center justify-center mx-auto mt-0 text-center sm:mt-10">
        <img
          src={blueBg}
          alt="blue bg"
          className="min-w-[100vw] min-h-[40vh] max-h-[40vh] absolute left-0 -z-10 -top-10"
        />
        <img
          src={blueLines}
          alt="blue bg"
          className="min-w-[100vw] min-h-[40vh] max-h-[40vh] absolute left-0 -z-10 -top-10"
        />
        <h2 className="p-10 text-2xl font-bold text-gray-700 mb-10">
          {t("fitness")}
        </h2>
        <div className="relative w-[90vw] sm:w-[80vw] bg-white rounded-b-lg shadow-lg">
          <img src={fitnes} alt="training pool" className="rounded-t-lg" />
          <div className="p-5">
  
          </div>
        </div>
        <img
          src={blueBg}
          alt="blue bg"
          className="min-w-[100vw] min-h-[40vh] max-h-[40vh] absolute left-0 -z-10 bottom-0"
        />
        <img
          src={blueLines}
          alt="blue bg"
          className="min-w-[100vw] min-h-[40vh] max-h-[40vh] absolute left-0 -z-10 bottom-0"
        />
      </div>

      <div className="container flex flex-col items-center justify-center mx-auto mt-0 text-center sm:mt-10">
        <img
          src={blueBg}
          alt="blue bg"
          className="min-w-[100vw] min-h-[40vh] max-h-[40vh] absolute left-0 mt-[300px] -z-10"
        />
        <img
          src={blueLines}
          alt="blue bg"
          className="min-w-[100vw] min-h-[40vh] max-h-[40vh] absolute left-0 mt-[300px] -z-10"
        />
        <h2 className="p-10 text-2xl font-bold text-gray-700 mb-10">
          {t("sauna")}
        </h2>
        <div className="relative w-[90vw] sm:w-[80vw] bg-white rounded-b-lg shadow-lg mb-5">
          <img
            src={sauna}
            alt="olympic swimming pool"
            className="rounded-t-lg"
          />
          <div className="p-5">
     
          </div>
        </div>
      </div>
    </div>
  );
};
