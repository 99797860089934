import bg from "../assets/images/home-bg.png";
import { InformationForm } from "../components/Registration/InformationForm";
import Modal from 'react-modal';
import { Contract } from "../components/Registration/Contract";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        outerHeight: '100px',
        border: 'solid blue 1px',
        borderRadius: '20px'
    },
};

export const Registration = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    
    const [registrations, setRegistrations] = useState([]);
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        address: '',
        phone: '',
        email: '',
        time: 0,        //months or appointments per week(kids fitness)
        timings: '',    //for kids fitness
        days: [],       //for kits fitness
        totalAmount: 0,
        payableAmount: 0,
        discount: 0,
        kidEntries: 0,
        swimmingExpertise: '',
        healthProblems: null,
        healthProblemDescription: '',
        type: 'Individual',
        premium: false,
        batchId: '0',
        paymentMethod: '',
        entries: '',
        ageGroup: '',
        age: 0,
        gender: ""
    });

    const [modalIsOpen, setIsOpen] = useState(false);
    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            setFormData({ ...formData, email: user.email });
        } else {
            openModal();
        }
    }, []);

    const [form, setForm] = useState("Individual");

    return (
        <div className="mt-40 font-custom2">
            <div className="flex flex-col items-center justify-center px-20 md:min-h-screen">
                <img src={bg} alt="bg"
                    className="min-w-[100vw] min-h-[30vh] md:min-h-[100vh] block absolute top-0 left-0 -z-10 " />
                <div className={`mt-10 sm:mt-20 md:mt-0 max-w-[95vw] ${step === 1 ? 'block' : 'hidden'}`}>
                    <InformationForm step={step} setStep={setStep} formData={formData} setFormData={setFormData} setRegistrations={setRegistrations} registrations={registrations} form={form} setForm={setForm}/>
                </div>
                <div className={`mt-10 sm:mt-20 md:mt-0 max-w-[95vw] ${step === 2 ? 'block' : 'hidden'}`}>
                    <Contract step={step} setStep={setStep} formData={formData} registrations={registrations} setRegistrations={setRegistrations} setFormData={setFormData} form={form} setForm={setForm}/>
                </div>
                <Modal
                    isOpen={modalIsOpen}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div className="flex flex-col items-center justify-center gap-10 p-5 px-10 max-w-[400px] text-center"> 
                        <h className='text-xl font-bold'>{t('choose_service')}</h>
                        <div className="flex flex-row gap-5">
                            <button onClick={() => { closeModal(); }} className="p-2 px-10 transition-all duration-200 border-2 border-blue-500 rounded-xl hover:bg-blue-500">{t('guest')}</button>
                            <button onClick={async () => {
                                closeModal();
                                navigate('/login')
                            }} className="p-2 px-10 transition-all duration-200 border-2 border-blue-500 rounded-xl hover:bg-blue-500">{t('login')}</button>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
};
